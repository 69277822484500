// External
import { useContext, useState } from "react";
import { BiDownload } from "react-icons/bi";

// Internal
import LoadingSpinner from "../UI/LoadingSpinner";
import PowerOfAttorney from "./PowerOfAttorney";
import SignaturePad from "../UI/SignaturePad";
import POA from "../../assets/files/power-of-attorney.pdf";
import { generatePOA } from "../../api/server";
import { AuthContext } from "../../context/auth-context";
import { uploadBase64String } from "../../util/uploadBase64String";

export default function SignVehiclePOA({ vehicle, onSubmit }) {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();

  const submitHandler = async (signature) => {
    try {
      setLoading(true);
      const signatureURL = await uploadBase64String(
        `partners/${user.uid}/signature`,
        signature
      );
      const url = await generatePOA(user, {
        vehicleId: vehicle.vin,
        signature: signatureURL,
      });
      onSubmit && onSubmit(url);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="loading-container">
        <LoadingSpinner />
      </div>
    );

  return (
    <div className="grid content-start gap-2">
      <div className="flex items-center justify-between">
        <p className="font-semibold md:text-lg text-stone-200">
          Power of Attorney for {vehicle.make} {vehicle.model} {vehicle.year}
        </p>
        <a
          href={POA}
          download="power-of-attorney-template.pdf"
          target="blank"
          rel="noopener noreferrer"
          className="flex items-center p-2 space-x-2"
        >
          <BiDownload className="inline-block w-6 h-6 text-stone-200" />
          <p className="hidden text-sm text-stone-200 md:block">Download Template</p>
        </a>
      </div>

      <div className="overflow-scroll h-[580px] border border-stone-400 py-4 px-2 rounded-md">
        <PowerOfAttorney vehicle={vehicle} />
      </div>

      <p className="my-4 text-sm text-center text-stone-200">
        Please sign in the box below:
      </p>
      <SignaturePad setSignature={submitHandler} />

      {warning && <p className="text-red-600">{warning}</p>}
    </div>
  );
}
