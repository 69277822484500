import { uploadString, ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
/**
This function takes a base64 String, uploads it to
firebase storage and returns the download URL 
*/
export function uploadBase64String(path, string) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!path) throw new Error("UploadBase64String: No path provided");
      if (!string) throw new Error("UploadBase64String: No string provided");

      const storageRef = ref(storage, path + ".png");
      const snapshot = await uploadString(
        storageRef,
        string.split(",")[1],
        "base64"
      );
      const url = await getDownloadURL(snapshot.ref);
      resolve(url);
    } catch (err) {
      reject(err);
    }
  });
}
