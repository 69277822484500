import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function SelectPair({ label, value, options, edit }) {
  return (
    <div>
      <p className="text-stone-400">{label}:</p>
      {!edit && <p className="text-stone-200">{value || "-"}</p>}
      {edit && (
        <select
          className="w-full form-input"
          defaultValue={value}
          name={label}
          id={label}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}
