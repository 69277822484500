import { addVehicle, decodeVin } from "../../api/server";
import { AuthContext } from "../../context/auth-context";
import Heading from "../UI/Heading";
import { useState, useContext } from "react";
import uploadFile from "../../util/uploadFile";
import LoadingSpinner from "../UI/LoadingSpinner";
import DetailPair from "../UI/DetailPair";
import Switch from "../UI/Switch";
import LinkPair from "../UI/LinkPair";
import SignVehiclePOA from "./SignVehiclePOA";
import Completion from "./Completion";
import { toTitleCase } from "../../util/toTitleCase";

export default function AddVehicle() {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();
  const [progress, setProgress] = useState(0);
  const [financed, setFinanced] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [item, setItem] = useState();

  // ==================================== VIN INPUT HANDLER
  /** This function is used to decode the VIN using POSH's API
   * and set the resulting vehicle information in the form */
  const vinInputHandler = async (vin) => {
    try {
      // Check if VIN is complete
      if (vin.length !== 17) return;
      setWarning();
      setLoading(true);
      // Send request to decode VIN
      const response = await decodeVin(user, { vin: vin });
      // Extract Results
      const vehicleInfo = response.vehicleInfo;

      // Set Results
      document.getElementById("Make").value = vehicleInfo.make?.toUpperCase();
      document.getElementById("Model").value = toTitleCase(
        vehicleInfo.model
      );
      document.getElementById("Year").value = vehicleInfo.year;

      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // ==================================== SUBMIT HANDLER
  const submitHandler = async () => {
    try {
      setLoading(true);
      setWarning();

      // Get Required Data
      const data = {
        vin: document.getElementById("VIN Number").value,
        make: document.getElementById("Make").value,
        model: document.getElementById("Model").value,
        year: document.getElementById("Year").value,
        financed: document.getElementById("financed").checked,
      };

      // Check for Required Data
      if (!data.vin || !data.make || !data.model || !data.year) {
        throw new Error("VIN, Make, Model and Year must be provided");
      }

      // Check VIN format
      if (data.vin.length !== 17)
        throw new Error("Incorrect VIN. Please check.");
      else data.vin = data.vin.toUpperCase();

      // Add License Info
      data.license = document.getElementById("License").value;
      data.state = document.getElementById("License State").value;
      if (data.state && data.state.length !== 2) {
        throw new Error(
          "Incorrect License State. Should be 2 characters long. e.g. MA"
        );
      } else data.state = data.state.toUpperCase();
      setVehicle(data);

      // Get Files
      const files = {
        bos: document.getElementById("Bill of Sale").files[0],
        title: document.getElementById("Title Document").files[0],
        registration: document.getElementById("Registration").files[0],
        vir: document.getElementById("Inspection Report").files[0],
        insurance: document.getElementById("Insurance Documents").files[0],
      };

      // Add Financed Info
      if (financed) {
        data.institution = document.getElementById(
          "Financial Institution"
        ).value;
        data.amount = document.getElementById("Financed Amount").value;
        data.term = document.getElementById("Term (months)").value;
        data.monthly = document.getElementById("Monthly Payment").value;
        data.due = document.getElementById("Due Date").value;
        files.loan = document.getElementById("Loan Documents").files[0];
      }

      // Upload Files
      await Promise.all(
        Object.keys(files).map(async (key) => {
          if (files[key]) {
            setItem(key);
            const path = `partners/${user.uid}/vehicles/${data.vin}/${key}`;
            const url = await uploadFile(path, files[key], setUploading);
            data[key] = url;
          }
        })
      );
      const response = await addVehicle(user, data);
      setProgress(1);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  if (progress === 0)
    return (
      <div className="grid content-start gap-6 bg">
        <Heading
          title="Add Vehicle"
          description={[
            `Please enter all information accurately 
        as the system might not be able to add your vehicle if 
        any information is incorrect.`,
            <br />,
            <br />,
            `DISCLAIMER: Adding a vehicle does not mean that it will be activated
          and become operational. Our Team will review the documentation 
          provided and contact you if necessary.  
        `,
          ]}
        ></Heading>

        <div className="grid gap-6">
          <div className="info">
            <div>
              <label className="form-label" htmlFor="VIN Number">
                VIN
              </label>
              <input
                className="w-full form-input"
                id="VIN Number"
                required={true}
                type="text"
                onChange={(e) => vinInputHandler(e.target.value)}
              />
            </div>
            <DetailPair label="Make" edit={true} required={true} />
            <DetailPair label="Model" edit={true} required={true} />
            <DetailPair
              label="Year"
              edit={true}
              type="number"
              required={true}
            />
            <DetailPair label="License" edit={true} />
            <DetailPair label="License State" edit={true} />
            <LinkPair label="Bill of Sale" edit={true} />
            <LinkPair label="Title Document" edit={true} />
            <LinkPair label="Registration" edit={true} />
            <LinkPair label="Inspection Report" edit={true} />
            <LinkPair label="Insurance Documents" edit={true} />
            <div className="form-checkbox">
              <label className="form-label">Financed:</label>
              <Switch
                onSwitch={setFinanced}
                name="financed"
                defaultOn={false}
              />
            </div>
          </div>

          {financed && (
            <div className="info">
              <DetailPair
                label="Financial Institution"
                edit={true}
                required={true}
              />
              <DetailPair
                label="Financed Amount"
                edit={true}
                required={true}
                type="number"
              />
              <DetailPair
                label="Term (months)"
                edit={true}
                required={true}
                type="number"
              />
              <DetailPair
                label="Due Date"
                edit={true}
                required={true}
                type="date"
              />
              <DetailPair
                label="Monthly Payment"
                edit={true}
                required={true}
                type="number"
              />
              <LinkPair label="Loan Documents" edit={true} required={true} />
            </div>
          )}

          <div className="items-center info">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <button
                onClick={submitHandler}
                className="btn-primary"
                type="submit"
              >
                Continue
              </button>
            )}
            {uploading && (
              <p className="text-stone-400">
                Uploading {item}: {uploading}%
              </p>
            )}
            {warning && <p className="text-red-600 md:col-span-2">{warning}</p>}
          </div>
        </div>
      </div>
    );

  if (progress === 1)
    return (
      <div className="bg">
        <SignVehiclePOA vehicle={vehicle} onSubmit={() => setProgress(2)} />
      </div>
    );

  if (progress === 2)
    return <Completion setProgress={setProgress} vehicle={vehicle} />;
}
