export default function LinkPair({ label, link, edit }) {
  return (
    <div className="cursor-pointer">
      <p className="text-stone-400">{label}:</p>

      {!edit &&
        (link ? (
          <a href={link} target="_blank" className="text-yellow-600 underline">
            Link
          </a>
        ) : (
          <p className="text-stone-200">-</p>
        ))}
      {edit && <input type="file" className="text-stone-200" id={label}/>}
    </div>
  );
}
