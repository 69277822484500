export default function SidebarButton({ title, onClick, selected }) {

  return (
    <div
      className={`text-sm w-full py-3 rounded-md cursor-pointer text-white text-center ${
        selected === title
          ? "bg-yellow-600 hover:bg-yellow-800"
          : "bg-stone-600 hover:bg-stone-700"
      }`}
      onClick={onClick}
    >
      {title}
    </div>
  );
}
