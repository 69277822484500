import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function BarGraph({ data }) {
  return (
    <div className="w-full my-4 h-52 md:my-8 md:h-42">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis tick={{ fontSize: 12 }} />
          <Tooltip wrapperStyle={{ color: "#000" }} />
          <Bar dataKey="revenue" stackId="a" fill="#ca8a04" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
