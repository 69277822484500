import { Fragment, useState, useContext } from "react";
import DataContext from "../../context/data-context";
import PaymentDetail from "./PaymentCard";
import SearchBar from "../UI/SearchBar";
import Heading from "../UI/Heading";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function Payments() {
  const { partner } = useContext(DataContext);
  const navigate = useNavigate();
  return (
    <div className="grid gap-4 overflow-hidden bg">
      <Heading
        title="Payments"
        description={`This lists shows all the payments you have 
        requested or received from us. To send a new request, click 
        the '+' icon.`}
      >
        <BsPlusLg
          onClick={() => navigate("/payments/add")}
          className="text-lg text-white cursor-pointer hover:text-stone-400"
        />
      </Heading>
      <SearchBar />
      <div className="max-h-[480px] overflow-scroll">
        <div className="grid gap-4">
          {partner.payments.map((payment) => (
            <PaymentDetail payment={payment} />
          ))}
        </div>
      </div>
      <p className="ml-auto text-sm text-stone-400">
        Showing {partner.payments.length} - Total 
        ${Number(partner.payments.reduce((acc, p) => acc + Number(p.amount), 0)).toFixed(2)}
      </p>
    </div>
  );
}
