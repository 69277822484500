import { useState, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { AiOutlinePlus } from "react-icons/ai";
import uploadFile from "../../util/uploadFile";
import { addDriversLicense } from "../../api/server";
import LoadingSpinner from "../UI/LoadingSpinner";

export default function DriversLicense({ setProgress }) {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();
  const [uploadProgress, setUploadProgress] = useState();

  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();

  const submitHandler = async () => {
    try {
      setLoading(true);
      const front = document.getElementById("front").files[0];
      const back = document.getElementById("back").files[0];

      if (!front || !back) {
        setLoading(false);
        setWarning("Please upload both images");
        return;
      }

      const path = `partners/${user.uid}`;

      const drivers_license_front = await uploadFile(
        path + "/drivers_license_front.png",
        front,
        setUploadProgress
      );

      const drivers_license_back = await uploadFile(
        path + "/drivers_license_back.png",
        back,
        setUploadProgress
      );

      await addDriversLicense(user, {
        drivers_license_front,
        drivers_license_back,
      });
      setProgress((progress) => progress + 1);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setWarning(err.message);
    }
  };

  const clickHandler = (name) => {
    document.getElementById(name).click();
  };

  const selectHandler = (e) => {
    const { name } = e.target;
    const file = document.getElementById(name).files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (name === "front") {
          setFrontImage(reader.result);
        } else {
          setBackImage(reader.result);
        }
      };
    }
  };

  return (
    <div className="grid content-start gap-4">
      <p className="text-lg font-semibold text-stone-200">Driver's License</p>
      <hr className="hr" />
      <div className="grid gap-1">
        <label className="form-label">Front:</label>
        <input
          className="form-input"
          name="front"
          type="file"
          id="front"
          hidden
          onChange={(e) => selectHandler(e)}
        />
        {frontImage ? (
          <div className="w-[240px] h-[140px]">
            <img
              src={frontImage}
              onClick={() => clickHandler("front")}
              className="object-contain h-[140px] rounded-lg cursor-pointer hover:opacity-75"
            />
          </div>
        ) : (
          <div
            onClick={() => clickHandler("front")}
            className="border rounded-lg w-[240px] h-[140px] border-stone-400 flex items-center justify-center hover:bg-stone-700 cursor-pointer"
          >
            <AiOutlinePlus className="w-16 h-16 text-stone-400" />
          </div>
        )}
      </div>
      <div className="grid gap-1">
        <label className="form-label">Back:</label>
        <input
          className="form-input"
          name="back"
          type="file"
          id="back"
          hidden
          onChange={(e) => selectHandler(e)}
        />
        {backImage ? (
          <div className="w-[240px] h-[140px]">
            <img
              src={backImage}
              onClick={() => clickHandler("back")}
              className="object-contain rounded-lg cursor-pointer hover:opacity-75 h-[140px]"
            />
          </div>
        ) : (
          <div
            onClick={() => clickHandler("back")}
            className="border rounded-lg w-[240px] h-[140px] border-stone-400 flex items-center justify-center hover:bg-stone-700 cursor-pointer"
          >
            <AiOutlinePlus className="w-16 h-16 text-stone-400" />
          </div>
        )}
      </div>

      <div className="grid items-center gap-4 mt-4 md:grid-cols-2">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <button onClick={submitHandler} className="btn-primary">
            Continue
          </button>
        )}
        {uploadProgress && (
          <p className="text-sm text-stone-400">{uploadProgress}</p>
        )}
        {warning && <p className="text-red-600">{warning}</p>}
      </div>
    </div>
  );
}
