import { useContext } from "react";
import DataContext from "../../context/data-context";
import VehicleCard from "./VehicleCard";
import SearchBar from "../UI/SearchBar";
import Heading from "../UI/Heading";
import { useNavigate } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";

export default function Vehicles() {
  const { fetchData, vehicles } = useContext(DataContext);

  const navigate = useNavigate();

  return (
    <div className="grid gap-4 overflow-hidden bg">
      <Heading
        title="Vehicles"
        description="The following list shows all vehicles added to the Partner's program. To add a new vehicle, click the '+' icon."
      >
        <AiOutlinePlus
          onClick={() => navigate("/vehicles/add")}
          className="icon"
        />

        <MdRefresh onClick={() => fetchData()} className="icon" />
      </Heading>
      <SearchBar />
      <div className="max-h-[600px] overflow-scroll ">
        <div className="grid gap-4">
          {vehicles.map((vehicle, idx) => (
            <VehicleCard key={idx} vehicle={vehicle} />
          ))}
        </div>
      </div>
    </div>
  );
}
