import { useState, useContext } from "react";
import Stepper from "./Stepper";
import Account from "./Account";
import DriversLicense from "./DriversLicense";
import Agreements from "./Agreement";
import DataContext from "../../context/data-context";

export default function Setup() {
  const { fetchData } = useContext(DataContext);
  const [progress, setProgress] = useState(0);

  const completeSetup = () => {
    fetchData();
  }

  return (
    <div className="flex justify-center p-4 md:items-center">
      <div className="p-8 md:p-16 rounded-xl bg-stone-900 md:h-[820px] md:w-[720px] w-full">
        <div className="mb-4">
          <Stepper progress={progress} steps={3} stepHandler={setProgress} />
        </div>
        {progress === 0 && <Account setProgress={setProgress} />}
        {progress === 1 && <DriversLicense setProgress={setProgress} />}
        {progress === 2 && <Agreements completeSetup={completeSetup} />}
      </div>
    </div>
  );
}
