import { format } from "date-fns";
import { Timestamp } from "firebase/firestore";

export default function timestampToDate(timestamp) {
  if (!timestamp) return;
  try {
    const date = new Timestamp(
      timestamp._seconds,
      timestamp._nanoseconds
    ).toDate();
    return date;
  } catch (err) {
    console.log(err);
    return "Invalid Date";
  }
}

export const timestampToString = (timestamp, dateFormat) => {
  if (!timestamp || !dateFormat) return "Invalid Date";
  try {
    const date = new Timestamp(
      timestamp._seconds,
      timestamp._nanoseconds
    ).toDate();
    return format(date, dateFormat);
  } catch (err) {
    console.log(err);
    return "Invalid Date";
  }
};