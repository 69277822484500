import { useContext, useState, Fragment } from "react";
import SignaturePad from "../UI/SignaturePad";
import { generateAgreement } from "../../api/server";
import { AuthContext } from "../../context/auth-context";
import LoadingSpinner from "../UI/LoadingSpinner";
import { uploadBase64String } from "../../util/uploadBase64String";
import PartnerAgreement from "../setup/PartnerAgreement";
import { BiDownload } from "react-icons/bi";
import AgreementPDF from "../../assets/files/partner-agreement.pdf";
import Heading from "../UI/Heading";
import DataContext from "../../context/data-context";
import { useNavigate } from "react-router-dom";

export default function Agreements() {
  const { user } = useContext(AuthContext);
  const { fetchData } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();

  const navigate = useNavigate();

  const submitHandler = async (signature) => {
    try {
      setLoading(true);
      const url = await uploadBase64String(
        `partners/${user.uid}/signature`,
        signature
      );
      const agreement = await generateAgreement(user, { signature: url });
      fetchData(() => {
        navigate("/");
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      setWarning(err.message);
    }
  };

  return (
    <div className="grid content-start gap-4 bg">
      <Heading
        title="Terms & Conditions"
        description={`Please scroll down to sign and submit the agreement.`}
      >
        <a
          href={AgreementPDF}
          download="partner-agreement.pdf"
          target="blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-2 rounded-md hover:text-stone-400 text-stone-200"
        >
          <BiDownload className="w-5 h-5" />
          <p className="text-sm">Download Agreement</p>
        </a>
      </Heading>
      <div className="h-[520px] overflow-scroll">
        <PartnerAgreement />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Fragment>
            <p className="my-4 text-sm text-center text-stone-200">
              Please sign in the box below:
            </p>
            <SignaturePad setSignature={submitHandler} />
          </Fragment>
        )}
      </div>
      {warning && <p className="text-red-600">{warning}</p>}
    </div>
  );
}
