/** ======================= FETCH Partner ======================= */
export const fetchPartner = async (user) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/fetch-partner`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= FETCH Vehicle ======================= */
export const fetchVehicle = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/fetch-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= CREATE Partner ======================= */
export const createPartner = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/create-partner`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EDIT PARTNER ======================= */
export const editPartner = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/edit-partner`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD Drivers License ======================= */
export const addDriversLicense = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/add-drivers-license`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= GENERATE AGREEMENT ======================= */
export const generateAgreement = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/generate-agreement`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD VEHICLE ======================= */
export const addVehicle = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/add-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= GET VEHICLE STATS ======================= */
export const getVehicleStats = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/get-vehicle-stats`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= REQUEST PAYMENT ======================= */
export const requestPayment = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/request-payment`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= GENERATE POA ======================= */
export const generatePOA = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/generate-poa`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= GENERATE VEHICLE AGREEMENT ======================= */
export const generateVA = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/generate-vehicle-agreement`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= EDIT VEHICLE ======================= */
export const editVehicle = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/edit-vehicle`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= ADD BANK INFORMATION ======================= */
export const addBankInfo = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/add-bank-info`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= CREDIT APPLICATION ======================= */
export const submitCreditApplication = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/submit-credit-app`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= CREDIT APP AGREEMENT ======================= */
export const signCreditAppAgreement = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/sign-credit-app`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/** ======================= DECODE VIN ======================= */
export const decodeVin = async (user, payload) => {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/partner/decode-vin`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_PARTNER_AUTH_TOKEN}`,
        userid: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};
