import { useNavigate, useSearchParams } from "react-router-dom";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import { useContext, useEffect, useState, Fragment } from "react";
import DataContext from "../../context/data-context";
import { timestampToString } from "../../util/timestamp";
import { toTitleCase } from "../../util/toTitleCase";
import { FaEdit } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import BarGraph from "../UI/BarGraph";
import { editVehicle, fetchVehicle, getVehicleStats } from "../../api/server";
import { AuthContext } from "../../context/auth-context";
import LinkPair from "../UI/LinkPair";
import { MdRefresh } from "react-icons/md";
import Switch from "../UI/Switch";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import uploadFile from "../../util/uploadFile";
import LoadingSpinner from "../UI/LoadingSpinner";
import SignVehiclePOA from "./SignVehiclePOA";

export default function VehicleInfo() {
  // Context
  const { user } = useContext(AuthContext);
  const { fetchData } = useContext(DataContext);

  // Get VIN from URL
  const [searchParams] = useSearchParams();
  const vin = searchParams.get("vin");

  // Navigate
  const navigate = useNavigate();

  // Initialize States
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(false);
  const [warning, setWarning] = useState();
  const [uploading, setUploading] = useState(false);
  const [item, setItem] = useState();

  const [vehicle, setVehicle] = useState();
  const [financed, setFinanced] = useState();
  const [edit, setEdit] = useState(false);
  const [poa, setPoa] = useState(false);

  // ========================================== FETCH VEHICLE
  const fetchVehicleHandler = async () => {
    try {
      setLoading(true);
      const data = await fetchVehicle(user, { vin: vin });
      setVehicle(data);
      setFinanced(data.financed);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // ========================================== FETCH VEHICLE REVENUE
  const fetchVehicleRevenueHandler = async (year) => {
    if (vehicle?.status !== "active") return;
    try {
      setLoading(true);
      const data = await getVehicleStats(user, {
        vehicles: [vehicle.id],
        year: year,
      });

      // Add Zero revenue for months with no revenue
      if (data.length < 12) {
        const length = data.length;
        for (let i = 0; i < 12 - length; i++) {
          const date = new Date();
          date.setMonth(length + i);
          const month = date.toLocaleString("default", { month: "short" });
          data.push({ name: month, revenue: 0 });
        }
      }
      setGraphData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // ========================================== EDIT HANDLER
  const submitHandler = async () => {
    try {
      setLoading(true);
      setWarning();

      // Get Required Data
      const data = {
        vin: vehicle.vin,
        make: document.getElementById("Make").value,
        model: document.getElementById("Model").value,
        year: document.getElementById("Year").value,
        financed: document.getElementById("financed").checked,
      };

      // Check for Required Data
      if (!data.make || !data.model || !data.year) {
        throw new Error("Make, Model and Year must be provided");
      }

      // Add License Info
      data.license = document.getElementById("License").value;
      data.state = document.getElementById("License State").value;
      if (data.state && data.state.length !== 2) {
        throw new Error(
          "Incorrect License State. Should be 2 characters long. e.g. MA"
        );
      } else data.state = data.state.toUpperCase();

      // Get Files
      const files = {
        bos: document.getElementById("Bill of Sale").files[0],
        title: document.getElementById("Title Document").files[0],
        registration: document.getElementById("Registration").files[0],
        vir: document.getElementById("Inspection Report").files[0],
        insurance: document.getElementById("Insurance Documents").files[0],
      };

      // Add Financed Info
      if (financed) {
        data.institution = document.getElementById(
          "Financial Institution"
        ).value;
        data.amount = document.getElementById("Financed Amount").value;
        data.term = document.getElementById("Term (months)").value;
        data.monthly = document.getElementById("Monthly Payment").value;
        data.due = document.getElementById("Due Date").value;
        files.loan = document.getElementById("Loan Documents").files[0];
      }

      // Upload Files
      await Promise.all(
        Object.keys(files).map(async (key) => {
          if (files[key]) {
            setItem(key);
            const path = `partners/${user.uid}/vehicles/${data.vin}/${key}`;
            const url = await uploadFile(path, files[key], setUploading);
            data[key] = url;
          }
        })
      );
      const response = await editVehicle(user, data);
      fetchData(() => {
        setEdit(false);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // ========================================== On Upload POA
  const onUploadPOA = () => {
    fetchData(() => {
      setPoa(false);
    });
  };

  useEffect(() => {
    if (!vehicle) fetchVehicleHandler();
    if (vehicle) fetchVehicleRevenueHandler(new Date().getFullYear());
  }, [vehicle]);

  if (!vehicle)
    return <div className="bg text-stone-400">Vehicle not found!</div>;

  if (poa)
    return (
      <div className="grid gap-4 bg">
        <SignVehiclePOA vehicle={vehicle} onSubmit={onUploadPOA} />
        <div onClick={() => setPoa(false)} className="btn-warning">
          Cancel
        </div>
      </div>
    );

  return (
    <div className="grid gap-6 bg max-h-[600px]">
      {vehicle.status === "active" && (
        <Fragment>
          <Heading
            title={`${vehicle.year} ${vehicle.make.toUpperCase()} ${
              vehicle.model
            }`}
            description={`The following graph shows revenue generated by this vehicle this year.
          Please note that revenue includes all incoming payments for bookings with this
          vehicle e.g. toll and ticket reimbursements etc.`}
          >
            {!vehicle.agreement && vehicle.status === "active" && (
              <div
                className="text-xs btn-warning"
                onClick={() => navigate(`/vehicles/sign?vin=${vin}`)}
              >
                Sign Vehicle Agreement
              </div>
            )}
            <select
              onChange={(e) => fetchVehicleRevenueHandler(e.target.value)}
              className="text-sm cursor-pointer input"
              defaultValue={new Date().getFullYear()}
            >
              {Array.from(
                { length: Number(new Date().getFullYear()) - 2021 },
                (v, i) => 2022 + i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <MdRefresh onClick={fetchData} className="icon" />
          </Heading>
          {loading ? <LoadingSpinnerWrapper /> : <BarGraph data={graphData} />}
        </Fragment>
      )}
      <Heading
        title={`Vehicle Information`}
        description={`Click the '+' icon to edit the vehicle information or upload documents. 
        Please note that once the vehicle is activated, you will not be able to make any changes.`}
      >
        {edit ? (
          <Fragment>
            <AiOutlineClose onClick={() => setEdit(false)} className="icon" />
            <AiOutlineCheck onClick={submitHandler} className="icon" />
          </Fragment>
        ) : (
          <Fragment>
            {vehicle.status !== "active" && (
              <FaEdit onClick={() => setEdit(true)} className="icon" />
            )}
            <MdRefresh onClick={fetchData} className="icon" />
          </Fragment>
        )}
      </Heading>
      <div className="grid gap-6">
        <div className="items-center info">
          <DetailPair label="POSH ID" value={vehicle.id} />
          <DetailPair label="VIN Number" value={vehicle.vin} />
          <DetailPair
            label="Date Added"
            value={timestampToString(vehicle.date_created, "MMM do yyyy")}
          />
          <DetailPair label="Make" value={vehicle.make} edit={edit} />
          <DetailPair label="Model" value={vehicle.model} edit={edit} />
          <DetailPair label="Year" value={vehicle.year} edit={edit} />
          <DetailPair label="License" value={vehicle.license} edit={edit} />
          <DetailPair label="License State" value={vehicle.state} edit={edit} />
          <DetailPair label="Status" value={toTitleCase(vehicle.status)} />
          <LinkPair label="Bill of Sale" link={vehicle.bos} edit={edit} />
          <LinkPair label="Title Document" link={vehicle.title} edit={edit} />
          <LinkPair
            label="Insurance Documents"
            link={vehicle.insurance}
            edit={edit}
          />
          <LinkPair
            label="Registration"
            link={vehicle.registration}
            edit={edit}
          />
          <LinkPair label="Inspection Report" link={vehicle.vir} edit={edit} />
          <LinkPair label="Vehicle Agreement" link={vehicle.agreement} />
          {!vehicle.poa ? (
            <div onClick={() => setPoa(true)} className="btn-secondary">
              Sign Power of Attorney
            </div>
          ) : (
            <LinkPair label="Power of Attorney" link={vehicle.poa} />
          )}
          {edit ? (
            <div className="form-checkbox md:col-span-2">
              <label className="form-label">Financed:</label>
              <Switch
                onSwitch={setFinanced}
                name="financed"
                defaultOn={vehicle.financed}
              />
            </div>
          ) : (
            <DetailPair
              label="Financed"
              value={vehicle.financed ? "Yes" : "No"}
            />
          )}
          {financed && (
            <Fragment>
              <DetailPair
                label="Financial Institution"
                value={vehicle.institution}
                edit={edit}
                required={true}
              />
              <DetailPair
                label="Financed Amount"
                value={vehicle.amount}
                edit={edit}
                required={true}
              />
              <DetailPair
                label="Monthly Payment"
                value={vehicle.monthly}
                edit={edit}
                required={true}
              />
              <DetailPair
                label="Due Date"
                value={vehicle.due}
                edit={edit}
                required={true}
              />
              <DetailPair
                label="Term (months)"
                value={vehicle.term}
                edit={edit}
                required={true}
              />
              <LinkPair
                label="Loan Documents"
                link={vehicle.loan}
                edit={edit}
              />
            </Fragment>
          )}
        </div>

        {loading ? (
          <div className="info">
            <LoadingSpinner />
            {uploading && (
              <p className="text-stone-400">
                Uploading {item}: {uploading}%
              </p>
            )}
          </div>
        ) : (
          <Fragment>
            {warning && <p className="text-sm text-red-700">{warning}</p>}
          </Fragment>
        )}
      </div>
    </div>
  );
}
