import { useState } from "react";
export default function Switch({ name, onSwitch, defaultOn }) {
  const [on, setOn] = useState(defaultOn);

  const button = () => {
    let classes =
      "w-6 h-6 rounded-full bg-stone-200 transition-all duration-500";
    if (on) {
      return classes + " translate-x-6 transform";
    } else return classes;
  };

  const background = () => {
    let classes =
      "flex items-center w-12 h-6 cursor-pointer transition-all duration-500 bg-stone-400 rounded-full";
    if (on) {
      return classes + " bg-green-600";
    } else return classes;
  };

  const switchHandler = () => {
    setOn(!on);
    onSwitch && onSwitch(!on);
    document.getElementsByName(name)[0].checked = !on;
  }

  return (
    <div onClick={switchHandler} className={background()}>
      <div className={button()}></div>
      <input type="checkbox" name={name} id={name} hidden />
    </div>
  );
}
