import Layout from "./components/layout/Layout";
import General from "./components/general/General";
import Payments from "./components/payments/Payments";
import { Routes, Route } from "react-router-dom";
import Vehicles from "./components/vehicles/Vehicles";
import Login from "./components/LoginPage";
import { useLocation } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import { useContext, useLayoutEffect, Fragment } from "react";
import LoadingSpinner from "./components/UI/LoadingSpinner";
import Setup from "./components/setup/Setup";
import DataContext from "./context/data-context";
import Navbar from "./components/layout/Navbar";
import AddVehicle from "./components/vehicles/AddVehicle";
import AddPayment from "./components/payments/RequestPayment";
import Settings from "./components/settings/Settings";
import VehicleInfo from "./components/vehicles/VehicleInfo";
import Agreement from "./components/settings/Agreement";
import DriversLicense from "./components/settings/DriversLicense";
import Profile from "./components/settings/Profile";
import SignVehicleAgreement from "./components/vehicles/SignVehicleAgreement";
import BankInfo from "./components/settings/BankInfo";
import CreditApplication from "./components/settings/CreditApplication";

function App() {
  const { user, loadingUser } = useContext(AuthContext);
  const { partner, loadingData } = useContext(DataContext);
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0, { behavior: "smooth" });
  }, [location.pathname]);

  if (loadingUser)
    return (
      <div className="h-screen loading-container">
        <LoadingSpinner />
      </div>
    );

  if (user && loadingData)
    return (
      <div className="h-screen loading-container">
        <LoadingSpinner />
      </div>
    );

  if (user) {
    if (partner) {
      return (
        <Layout>
          <Routes>
            <Route path="/" element={<General />} />
            <Route path="/general" element={<General />} />

            <Route path="/vehicles" element={<Vehicles />} />
            <Route path="/vehicles/info" element={<VehicleInfo />} />
            <Route path="/vehicles/add" element={<AddVehicle />} />
            <Route path="/vehicles/sign" element={<SignVehicleAgreement />} />

            <Route path="/payments" element={<Payments />} />
            <Route path="/payments/add" element={<AddPayment />} />

            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/profile" element={<Profile />} />
            <Route path="/settings/drivers-license" element={<DriversLicense />} />
            <Route path="/settings/bank-info" element={<BankInfo />} />
            <Route path="/settings/agreement" element={<Agreement />} />
            <Route path="/settings/credit-app" element={<CreditApplication />} />
          </Routes>
        </Layout>
      );
    } else
      return (
        <Fragment>
          <Navbar />
          <Setup />
        </Fragment>
      );
  } else return <Login />;
}

export default App;
