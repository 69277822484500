import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../context/data-context";
import SidebarButton from "../UI/SidebarButton";
import { AuthContext } from "../../context/auth-context";
import { FaUserCircle } from "react-icons/fa";

export default function Sidebar() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { partner } = useContext(DataContext);
  const [selected, setSelected] = useState("General");

  const selectHandler = (item) => {
    setSelected(item);
    navigate(`/${item.toLowerCase()}`);
  };

  return (
    <div className="hidden w-full px-6 py-12 bg-stone-900 rounded-xl md:block">
      <div className="flex flex-col items-center space-y-2">
        <div className="w-16 h-16">
          {partner.photoURL ? (
            <img src={partner.photoURL} className="w-16 h-16 rounded-full contain" />
          ) : (
            <FaUserCircle className="w-full h-full text-yellow-600 cursor-pointer" />
          )}
        </div>
        <p className="text-center text-stone-300">
          {partner?.name || user.displayName}
        </p>
      </div>
      <div className="grid gap-8 mt-16 mb-12">
        <SidebarButton
          title="General"
          selected={selected}
          onClick={() => selectHandler("General")}
        />
        <SidebarButton
          title="Vehicles"
          selected={selected}
          onClick={() => selectHandler("Vehicles")}
        />
        <SidebarButton
          title="Payments"
          selected={selected}
          onClick={() => selectHandler("Payments")}
        />
        <SidebarButton
          title="Settings"
          selected={selected}
          onClick={() => selectHandler("Settings")}
        />
      </div>
    </div>
  );
}
