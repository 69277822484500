import { useContext, useState } from "react";
import uploadFile from "../../util/uploadFile";
import Heading from "../UI/Heading";
import { AuthContext } from "../../context/auth-context";
import { useNavigate } from "react-router-dom";
import DataContext from "../../context/data-context";
import LoadingSpinner from "../UI/LoadingSpinner";
import { requestPayment } from "../../api/server";
import { AiOutlineClose } from "react-icons/ai";

export default function RequestPayment() {
  const { user } = useContext(AuthContext);
  const { partner, vehicles, fetchData } = useContext(DataContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [uploading, setUploading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setWarning();
      const data = {
        id: `${Date.now()}`,
        amount: e.target.amount.value,
        description: e.target.description.value,
        vehicleId: e.target.vehicle.value,
      };

      if (e.target.document.files[0]) {
        const path = `partners/${
          user.uid
        }/payments/${Date.now()}.${e.target.document.files[0].name
          .split(".")
          .pop()}`;
        data.document = await uploadFile(
          path,
          e.target.document.files[0],
          setUploading
        );
        setUploading(false);
      }

      const response = await requestPayment(user, data);
      fetchData(() => navigate("/payments"));

      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="grid content-start gap-6 bg">
      <Heading
        title="Request Payment"
        description={`Please make sure to select the vehicle pertaining 
        to the payment. You will receive a notification once the payment is approved.`}
      >
        <AiOutlineClose onClick={() => navigate("/payments")} className="icon"/>
      </Heading>

      <form onSubmit={submitHandler} className="grid gap-8">
        <div className="grid gap-4 md:grid-cols-4">
          <div className="grid">
            <p className="form-label">Amount:</p>
            <input className="form-input" name="amount" required />
          </div>
          <div className="grid md:col-span-3">
            <p className="form-label">Description:</p>
            <input className="form-input" name="description" required />
          </div>
          <div className="grid md:col-span-2">
            <p className="form-label">Vehicle:</p>
            <select className="cursor-pointer form-input" name="vehicle">
              {vehicles.map((vehicle) => {
                if (vehicle.status !== "pending")
                  return (
                    <option value={vehicle.id}>
                      {vehicle.model} {vehicle.year} {vehicle.license || "-"}
                    </option>
                  );
              })}
            </select>
          </div>
          <div className="grid md:col-span-2">
            <p className="form-label">Documentation:</p>
            <input
              className="form-input"
              name="document"
              type="file"
              required
            />
          </div>
        </div>

        <div className="grid items-center gap-4 md:grid-cols-3">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <button className="btn-primary" type="submit">
              Submit
            </button>
          )}
          {uploading && (
            <p className="text-stone-400">
              Uploading Documentation: {uploading}%
            </p>
          )}
          {warning && <p className="text-red-600">{warning}</p>}
        </div>
      </form>
    </div>
  );
}
