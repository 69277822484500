export default function Heading({ title, children, description }) {
  return (
    <div className="grid gap-2">
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold text-white">
          {title}
        </p>
        <div className="flex items-center space-x-6">
          {children}
        </div>
      </div>
      <hr className="hr" />
      <p className="text-xs text-stone-400">{description}</p>
    </div>
  );
}
