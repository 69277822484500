import { Fragment, useContext, useState } from "react";
import DetailPair from "../UI/DetailPair";
import SelectPair from "../UI/SelectPair";
import Heading from "../UI/Heading";
import DataContext from "../../context/data-context";
import { FaEdit } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import LinkPair from "../UI/LinkPair";
import LoadingSpinner from "../UI/LoadingSpinner";
import uploadFile from "../../util/uploadFile";
import { AuthContext } from "../../context/auth-context";
import {
  signCreditAppAgreement,
  submitCreditApplication,
} from "../../api/server";
import { BiDownload } from "react-icons/bi";
import CreditAppAgreement from "./CreditAppAgreement";
import AgreementPDF from "../../assets/files/credit-app-agreement.pdf";
import SignaturePad from "../UI/SignaturePad";
import { uploadBase64String } from "../../util/uploadBase64String";

export default function CreditApplication() {
  const { user } = useContext(AuthContext);
  const { partner, fetchData } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [item, setItem] = useState();
  const [warning, setWarning] = useState();
  const [edit, setEdit] = useState(false);
  const [step, setStep] = useState(0);

  // =================================================== SUBMIT HANDLER
  const submitHandler = async () => {
    try {
      setLoading(true);
      setWarning();
      const data = {
        drivers_license_number:
          document.getElementById("Driver's License Number").value || false,
        phone: document.getElementById("Phone").value || false,
        ssn: document.getElementById("Social Security Number").value || false,
        dob: document.getElementById("Date of Birth").value || false,
        residence_type:
          document.getElementById("Residence Type").value || false,
        monthly_rent:
          document.getElementById("Monthly Rent/Mortgage").value || false,
        years_at_residence:
          document.getElementById("Years at Residence").value || false,
        months_at_residence:
          document.getElementById("Months at Residence").value || false,
        employer: document.getElementById("Employer").value || false,
        occupation: document.getElementById("Occupation").value || false,
        employment_years:
          document.getElementById("Years at Current Employment").value || false,
        monthly_salary:
          document.getElementById("Monthly Salary").value || false,
        other_income: document.getElementById("Other Income").value || false,
      };

      const files = {
        tax_returns_1:
          document.getElementById(
            `Tax Returns ${Number(new Date().getFullYear()) - 1}`
          ).files[0] || false,
        tax_returns_2:
          document.getElementById(
            `Tax Returns ${Number(new Date().getFullYear()) - 2}`
          ).files[0] || false,
        ssn_front: document.getElementById("Picture of SSN").files[0] || false,
        proof_of_residence:
          document.getElementById("Proof of Residence").files[0] || false,
        pay_stubs: document.getElementById("Pay Stub (YTD)").files[0] || false,
        bank_statements:
          document.getElementById("Bank Statements (last 3 months)").files[0] ||
          false,
        selfie_id: document.getElementById("Selfie with ID").files[0] || false,
      };

      // Upload Files
      await Promise.all(
        Object.keys(files).map(async (key) => {
          if (files[key]) {
            setItem(key);
            const path = `partners/${user.uid}/${key}`;
            setItem(key);
            const url = await uploadFile(path, files[key], setUploading);
            data[key] = url;
          } else if (partner[key]) data[key] = partner[key];
          else data[key] = false;
        })
      );

      await submitCreditApplication(user, data);
      fetchData(() => {
        setLoading(false);
        setEdit(false);
        setUploading(false);
        setStep(0);
      });
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setEdit(false);
      setLoading(false);
    }
  };

  // =================================================== SIGN HANDLER
  const signHandler = async (signature) => {
    try {
      setLoading(true);
      const url = await uploadBase64String(
        `partners/${user.uid}/signature`,
        signature
      );
      await signCreditAppAgreement(user, { signature: url });
      fetchData(() => {
        setLoading(false);
        setStep(0);
      });
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  if (step === 0)
    return (
      <div className="bg max-h-[600px] overflow-scroll">
        <Heading
          title="Credit Application"
          description="The following information is collected and used to apply for vehicles loans on your behalf.
         You will be asked to sign a Power of Attorney and acknowledge disclaimers regarding how this information is used."
        >
          {edit ? (
            <Fragment>
              <AiOutlineCheck className="icon" onClick={submitHandler} />
              <AiOutlineClose className="icon" onClick={() => setEdit(false)} />
            </Fragment>
          ) : (
            <FaEdit className="icon" onClick={() => setEdit(true)} />
          )}
        </Heading>
        <div className="info">
          <DetailPair label="Name" value={partner?.name} />
          <DetailPair label="Address" value={partner.address} />
          <DetailPair
            label="Driver's License Number"
            value={partner.drivers_license_number}
            edit={edit}
          />
          <DetailPair label="Phone" value={partner.phone} edit={edit} />
          <DetailPair
            label="Social Security Number"
            value={partner.ssn}
            edit={edit}
          />
          <DetailPair
            label="Date of Birth"
            value={partner.dob}
            edit={edit}
            type="date"
          />

          <div className="md:col-span-3">
            <Heading title="Residence" />
          </div>

          <SelectPair
            label="Residence Type"
            value={partner.residence_type}
            options={["Own", "Rent", "Other"]}
            edit={edit}
          />
          <DetailPair
            label="Monthly Rent/Mortgage"
            value={partner.monthly_rent}
            edit={edit}
          />
          <DetailPair
            label="Years at Residence"
            value={partner.years_at_residence}
            edit={edit}
            type="number"
          />
          <DetailPair
            label="Months at Residence"
            value={partner.months_at_residence}
            edit={edit}
            type="number"
          />

          <div className="md:col-span-3">
            <Heading title="Employment" />
          </div>

          <DetailPair label="Employer" value={partner.employer} edit={edit} />
          <DetailPair
            label="Occupation"
            value={partner.occupation}
            edit={edit}
          />
          <DetailPair
            label="Years at Current Employment"
            value={partner.employment_years}
            edit={edit}
          />
          <DetailPair
            label="Monthly Salary"
            value={partner.monthly_salary}
            type="number"
            edit={edit}
          />
          <DetailPair
            label="Other Income"
            value={partner.other_income}
            type="number"
            edit={edit}
          />

          <div className="md:col-span-3">
            <Heading title="Documents" />
          </div>

          <LinkPair
            label={`Tax Returns ${Number(new Date().getFullYear()) - 1}`}
            value={partner.tax_returns_1}
            edit={edit}
          />
          <LinkPair
            label={`Tax Returns ${Number(new Date().getFullYear()) - 2}`}
            value={partner.tax_returns_2}
            edit={edit}
          />
          <LinkPair
            label="Picture of SSN"
            value={partner.ssn_front}
            edit={edit}
          />
          <LinkPair
            label="Proof of Residence"
            value={partner.proof_of_residence}
            edit={edit}
          />
          <LinkPair
            label="Pay Stub (YTD)"
            value={partner.pay_stubs}
            edit={edit}
          />
          <LinkPair
            label="Bank Statements (last 3 months)"
            value={partner.bank_statements}
            edit={edit}
          />
          <LinkPair
            label="Selfie with ID"
            value={partner.selfie_id}
            edit={edit}
          />
        </div>
        <div className="info">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              {partner.credit_app_agreement ? (
                <a
                  href={partner.credit_app_agreement}
                  className="btn-secondary"
                  target="blank"
                >
                  Download Agreement
                </a>
              ) : (
                <div className="btn-secondary" onClick={() => setStep(1)}>
                  Sign Agreement
                </div>
              )}
              {edit && (
                <div className="btn-primary" onClick={submitHandler}>
                  Submit
                </div>
              )}
            </Fragment>
          )}
          {uploading && (
            <p className="text-stone-400">
              Uploading {item}... {uploading}%
            </p>
          )}
          {warning && <p className="text-red-600">{warning}</p>}
        </div>
      </div>
    );

  if (step === 1)
    return (
      <div className="grid content-start gap-4 bg">
        <Heading title="Credit Application Agreement">
          <a
            href={AgreementPDF}
            download="partner-agreement.pdf"
            target="blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 rounded-md"
          >
            <BiDownload className="inline-block w-6 h-6 text-stone-200" />
            <p className="text-sm text-stone-200">Download Agreement</p>
          </a>
        </Heading>
        <div className="h-[520px] overflow-scroll">
          <CreditAppAgreement />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              <p className="my-4 text-sm text-center text-stone-200">
                Please sign in the box below:
              </p>
              <SignaturePad setSignature={signHandler} />
            </Fragment>
          )}
        </div>
        <div className="info">
          <div onClick={() => setStep(0)} className="btn-secondary">
            Return to Application
          </div>
          {warning && <p className="text-red-600 md:col-span-2">{warning}</p>}
        </div>
      </div>
    );
}
