export default function SettingsOption({ link, title, onClick, children }) {
  if (link)
    return (
      <a
        href={link}
        target="_blank"
        className="flex items-center space-x-4 text-stone-200 hover:text-stone-400"
      >
        {children}
        <p className="text-sm cursor-pointer">{title}</p>
      </a>
    );
  return (
    <div
      onClick={onClick && onClick}
      className="flex items-center space-x-4 text-stone-200 hover:text-stone-400"
    >
      {children}
      <p className="text-sm cursor-pointer">{title}</p>
    </div>
  );
}
