import { useNavigate } from "react-router-dom";
import { IoIosWarning } from "react-icons/io";

export default function WarningBar({ message, link }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(link)}
      className="flex items-center px-4 py-2 space-x-4 text-sm border-2 border-red-700 rounded-md cursor-pointer hover:bg-stone-700 w-fit"
    >
      <IoIosWarning className="m-auto text-2xl text-red-700" />
      <p className="text-stone-400">{message}</p>
    </div>
  );
}
