// External
import { useState, useContext } from "react";
import { BiDownload } from "react-icons/bi";

// Internal
import { AuthContext } from "../../context/auth-context";
import DataContext from "../../context/data-context";
import VA from "../../assets/files/vehicle-agreement.pdf";
import { uploadBase64String } from "../../util/uploadBase64String";
import LoadingSpinner from "../UI/LoadingSpinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignaturePad from "../UI/SignaturePad";
import VehicleAgreement from "./VehicleAgreement";
import { generateVA } from "../../api/server";

export default function SignVehicleAgreement() {
  const { user } = useContext(AuthContext);
  const { vehicles, fetchData } = useContext(DataContext);

  // Get VIN from URL
  const [searchParams] = useSearchParams();
  const vin = searchParams.get("vin");

  // Get Vehicle
  const vehicle = vehicles.filter((vehicle) => vehicle.vin === vin)[0];

  // Navigate
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();

  const submitHandler = async (signature) => {
    try {
      setLoading(true);
      const signatureURL = await uploadBase64String(
        `partners/${user.uid}/signature`,
        signature
      );
      const url = await generateVA(user, {
        vehicleId: vehicle.vin,
        signature: signatureURL,
      });
      fetchData(() => navigate("/vehicles/info?vin=" + vehicle.vin));
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setWarning(err.message);
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="loading-container">
        <LoadingSpinner />
      </div>
    );

  return (
    <div className="grid content-start gap-2 bg">
      <div className="flex items-center justify-between">
        <p className="font-semibold md:text-lg text-stone-200">
          Vehicle Agreement for {vehicle.make} {vehicle.model} {vehicle.year}
        </p>
        <a
          href={VA}
          download="power-of-attorney-template.pdf"
          target="blank"
          rel="noopener noreferrer"
          className="flex items-center p-2 space-x-2"
        >
          <BiDownload className="inline-block w-6 h-6 text-stone-200" />
          <p className="hidden text-sm text-stone-200 md:block">
            Download Template
          </p>
        </a>
      </div>

      <div className="overflow-scroll h-[580px] border border-stone-400 py-4 px-2 rounded-md">
        <VehicleAgreement vehicle={vehicle} />
      </div>

      <p className="my-4 text-sm text-center text-stone-200">
        Please sign in the box below:
      </p>
      <SignaturePad setSignature={submitHandler} />

      {warning && <p className="text-red-600">{warning}</p>}
    </div>
  );
}
