import { useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DataContext from "../../context/data-context";

export default function Completion({ vehicle, setProgress }) {
  const { fetchData } = useContext(DataContext);
  const navigation = useNavigate();

  const closeHandler = (navigate) => {
    fetchData(() => {
      if (navigate) navigation(`/vehicles/info?vin=${vehicle.vin}`);
      else setProgress(0);
    });
  };
  return (
    <div className="relative grid content-center text-sm text-center bg">
      <AiOutlineClose
        onClick={() => closeHandler(false)}
        className="absolute right-5 top-5 icon"
      />
      <p className="mb-12 text-2xl font-Play">Congratulations! 🎉</p>
      <p className="text-stone-400">
        You have successfully completed adding a new vehicle.
      </p>
      <p className="text-stone-400">
        Our team will review your submission and reach out with any questions.
      </p>
      <p className="mb-14 text-stone-400">
        Once the vehicle is approved, you will be able to sign the vehicle
        agreement on the vehicle information page.
      </p>
      <button onClick={() => closeHandler(true)} className="btn-secondary">
        Go to Vehicle Information Page
      </button>
    </div>
  );
}
