import { useContext, useState, Fragment } from "react";
import DetailPair from "../UI/DetailPair";
import DataContext from "../../context/data-context";
import Heading from "../UI/Heading";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { addBankInfo } from "../../api/server";
import { AuthContext } from "../../context/auth-context";

export default function BankInfo() {
  const { user } = useContext(AuthContext);
  const { partner, fetchData } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();
  const [edit, setEdit] = useState(false);

  const editHandler = async () => {
    try {
      setLoading(true);
      setWarning();
      const data = {
        name: document.getElementById("Bank Name").value,
        account: document.getElementById("Account Number").value,
        routing: document.getElementById("Routing Number").value,
      };

      if (!data.name || !data.account || !data.routing) {
        throw new Error("All fields are required");
      }

      await addBankInfo(user, data);
      fetchData(() => {
        setEdit(false);
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      setWarning(err.message);
      console.log(err);
    }
  };

  return (
    <div className="bg">
      <Heading
        title="Bank Information"
        description="The following information is used to process payments to you."
      >
        {edit ? (
          <Fragment>
            <AiOutlineCheck onClick={editHandler} className="icon" />
            <AiOutlineClose onClick={() => setEdit(false)} className="icon" />
          </Fragment>
        ) : (
          <FaEdit onClick={() => setEdit(true)} className="icon" />
        )}
      </Heading>

      <div className="info">
        <DetailPair
          label="Bank Name"
          value={partner.bank?.name}
          edit={edit}
          required={true}
        />
        <DetailPair
          label="Account Number"
          value={partner.bank?.account}
          edit={edit}
          type="number"
          required={true}
        />
        <DetailPair
          label="Routing Number"
          value={partner.bank?.routing}
          edit={edit}
          type="number"
          required={true}
        />
      </div>
    </div>
  );
}
