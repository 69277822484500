import { useState, useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { createPartner } from "../../api/server";
import LoadingSpinner from "../UI/LoadingSpinner";
import { formatPhone } from "../../util/formatPhone";

export default function Account({ setProgress }) {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        name: e.target.name.value,
        dob: e.target.dob.value,
        phone: formatPhone(e.target.phone.value),
        email: user.email,
        address: e.target.address.value,
        code: e.target.code.value,
      };
      await createPartner(user, payload);
      setProgress((progress) => progress + 1);
    } catch (err) {
      setWarning(err.message);
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="loading-container">
        <LoadingSpinner />
      </div>
    );

  return (
    <form
      onSubmit={submitHandler}
      className="grid items-center content-start gap-4 md:grid-cols-2"
    >
      <div className="grid gap-4 md:col-span-2">
        <p className="text-lg font-semibold text-stone-200">Account Setup</p>
        <hr className="hr" />
      </div>
      <div className="grid gap-1">
        <label className="form-label">Full Name:</label>
        <input className="form-input" name="name" required />
      </div>
      <div className="grid gap-1">
        <label className="form-label">Date of Birth:</label>
        <input className="form-input" name="dob" placeholder="MM/DD/YYYY" />
      </div>

      <div className="grid gap-1">
        <label className="form-label">Phone Number:</label>
        <input className="form-input" name="phone" required />
      </div>
      <div className="grid gap-1">
        <label className="form-label">Email Address:</label>
        <input className="text-white form-input-disabled" value={user.email} disabled />
      </div>

      <div className="grid gap-1 md:col-span-2">
        <label className="form-label">Mailing Address:</label>
        <input className="form-input" name="address" required />
      </div>

      <div className="grid gap-1 md:col-span-2">
        <label className="form-label">Invitation Code:</label>
        <input className="form-input" name="code" required />
      </div>

      <button type="submit" className="mt-4 btn-primary">
        Continue
      </button>

      {warning && <p className="text-sm text-red-600">{warning}</p>}
    </form>
  );
}
