export default function CreditAppAgreement() {
  return (
    <div className="grid gap-4 text-sm text-stone-400">
      <p className="italic">
        Thank you for your interest in being POSH&apos;s Financial Partner!
      </p>
      <p className="font-semibold text-stone-200">
        PLEASE READ THE FOLLOWING CAREFULLY. These Terms and Conditions
        ("Agreement") govern the provision of consumer credit report services
        ("Services") by POSHIT Inc ("POSHIT", "we", "us", or "our") to its
        financial partners ("Partners", "you", or "your"), in accordance with
        Experian's compliance standards. By accessing or using our Services, you
        agree to be bound by this Agreement.
      </p>

      <p className="font-semibold text-stone-200">Scope of Services:</p>
      <p>
        POSHIT Inc provides consumer credit report services to its Partners to
        assist them in making informed decisions regarding creditworthiness and
        financial risk assessment of individuals. The Services include accessing
        credit reports from Experian, a third-party credit bureau, and providing
        credit scores and related information as agreed upon between POSHIT Inc
        and the Partners.
      </p>
      <p className="font-semibold text-stone-200">
        Compliance with Experian's Requirements:
      </p>
      <p>
        Partners shall comply with all applicable requirements, guidelines, and
        standards set forth by Experian for the use and handling of consumer
        credit information. POSHIT Inc shall ensure that its Services are
        provided in accordance with Experian's compliance standards and shall
        promptly notify Partners of any changes or updates to such standards.
      </p>
      <p className="font-semibold text-stone-200">
        Confidentiality and Security:
      </p>
      <p>
        POSHIT Inc and its Partners shall maintain strict confidentiality of
        consumer credit information obtained through the Services. Partners
        shall implement reasonable security measures to protect consumer credit
        information, including encryption, access controls, and secure storage,
        in accordance with Experian's requirements.
      </p>
      <p className="font-semibold text-stone-200">Use Restrictions:</p>
      <p>
        Partners shall use the Services solely for legitimate business purposes,
        such as assessing credit risk for consumer transactions, employment
        purposes, tenant screening, or as otherwise permitted by applicable law
        and Experian's guidelines. Partners shall not use consumer credit
        information obtained through the Services for unauthorized purposes or
        disclose such information to any unauthorized third parties.
      </p>
      <p className="font-semibold text-stone-200">Fees and Payment:</p>
      <p>
        Partners agree to pay the fees for the Services as outlined in the
        applicable pricing plan or as otherwise agreed upon between POSHIT Inc
        and the Partners. Payment shall be made in accordance with the
        agreed-upon terms, and failure to pay may result in suspension or
        termination of access to the Services.
      </p>
      <p className="font-semibold text-stone-200">Intellectual Property:</p>
      <p>
        All intellectual property rights in the Services, including but not
        limited to software, algorithms, and databases, are owned by POSHIT Inc
        or its licensors. Partners shall not reverse engineer, modify, or
        distribute any part of the Services without prior written consent from
        POSHIT Inc.
      </p>
      <p className="font-semibold text-stone-200">Disclaimer of Warranty:</p>
      <p>
        POSHIT Inc makes no warranties, express or implied, regarding the
        accuracy, completeness, or reliability of the consumer credit
        information provided through the Services. Partners acknowledge that
        credit reports are based on data provided by Experian and may not always
        be up-to-date or error-free.
      </p>
      <p className="font-semibold text-stone-200">Limitation of Liability:</p>
      <p>
        In no event shall POSHIT Inc be liable for any indirect, incidental,
        special, or consequential damages arising out of or in connection with
        the use of the Services, even if advised of the possibility of such
        damages.
      </p>
      <p className="font-semibold text-stone-200">Term and Termination:</p>
      <p>
        This Agreement shall remain in effect until terminated by either party.
        Either party may terminate this Agreement upon written notice if the
        other party breaches any material provision of this Agreement and fails
        to cure such breach within a reasonable period.
      </p>
      <p className="font-semibold text-stone-200">Governing Law:</p>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of Delaware, US, without regard to its conflicts of law principles.
      </p>
      <p className="font-semibold text-stone-200">Amendments:</p>
      <p>
        POSHIT Inc reserves the right to modify or amend this Agreement at any
        time. Any changes will be notified to the Partners in writing or through
        the POSHIT Inc website. By using the Services, you acknowledge that you
        have read, understood, and agree to be bound by these Terms and
        Conditions. If you do not agree with any provision of this Agreement,
        you may not use the Services.
      </p>
      <p className="font-semibold text-stone-200">
        By signing this document you authorize POSHIT Inc to use the following
        information collected on POSH's online platform (partner.poshcars.io)
        for communicating with banks and credit unions for the purposes of
        securing vehicle loans:
      </p>
      <ol>
        <li>Full Name</li>
        <li>Date of Birth</li>
        <li>Driver's License Number</li>
        <li>Social Security Number</li>
        <li>Residence Information</li>
        <li>Employment Information</li>
        <li>Tax and Financial Information</li>
        <li>Bank Statements and Pay Stubs</li>
      </ol>
    </div>
  );
}
