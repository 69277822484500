import { timestampToString } from "../../util/timestamp";

export default function PaymentDetail({ payment }) {
  const classNames = () => {
    let classes =
      "items-center p-4 text-sm border rounded-md grid md:grid-cols-5 hover:bg-stone-800 text-stone-400 ";
    if (payment.status === "succeeded") classes += "border-green-600";
    if (payment.status === "approved") classes += "border-blue-600";
    if (payment.status === "declined") classes += "border-red-600";
    if (payment.status === "pending") classes += "border-yellow-600";
    return classes;
  };
  return (
    <div className={classNames()}>
      <p className="font-semibold">
        {timestampToString(
          payment.date_paid || payment.date_created,
          "do MMM hh:mm a"
        )}
      </p>
      <p className="md:col-span-2 text-stone-200">{payment.description}</p>
      {payment.attachment ? (
        <a
          href={payment.attachment || "-"}
          target="_blank"
          className="text-right text-yellow-600 underline"
        >
          Attachment
        </a>
      ) : (
        <p>-</p>
      )}
      <p className="font-semibold text-right text-white">${Number(payment.amount).toFixed(2)}</p>
    </div>
  );
}
