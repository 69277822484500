export default function PowerOfAttorney({ vehicle }) {
  return (
    <div className="grid gap-4 text-sm text-stone-400">
      <p className="italic">
        Thank you for your interest in adding a vehicle to our fleet.
      </p>
      <p className="font-semibold text-stone-200">
        PLEASE READ THE FOLLOWING CAREFULLY AND SIGN AT THE BOTTOM OF THE PAGE.
      </p>
      <div className="grid gap-2">
        <p>
          This Power of Attorney document is designed to grant authority to an
          appointed individual, referred to as the "Attorney-in-Fact," to act on
          behalf of the grantor in matters pertaining to a specific vehicle. By
          executing this Power of Attorney, the grantor (the vehicle owner)
          delegates certain powers and responsibilities to the Attorney-in-Fact,
          allowing them to conduct various transactions and make decisions
          concerning the specified vehicle.
        </p>
        <p>
          <span className="font-semibold text-stone-200">Key Components:</span>
        </p>
        <ol className="grid gap-2 pl-6 list-decimal">
          <li>
            <p>
              <span className="font-semibold text-stone-200">
                Grantor Information:
              </span>{" "}
              This section identifies the individual granting the power,
              including their full legal name, address, and any other pertinent
              identifying details.
            </p>
          </li>
          <li>
            <p>
              <span className="font-semibold text-stone-200">
                Attorney-in-Fact Information:
              </span>{" "}
              Here, the appointed individual, known as the Attorney-in-Fact, is
              named. Their full name, address, and relevant identifying
              information are provided.
            </p>
          </li>
          <li>
            <p>
              <span className="font-semibold text-stone-200">
                Vehicle Details:
              </span>{" "}
              Precise information about the vehicle subject to the Power of
              Attorney is included. This typically involves the vehicle's make,
              model, year of manufacture, Vehicle Identification Number (VIN),
              license plate number, and any other relevant details necessary for
              identification.
            </p>
          </li>
          <li>
            <p>
              <span className="font-semibold text-stone-200">
                Scope of Authority:
              </span>{" "}
              The document specifies the scope of authority granted to the
              Attorney-in-Fact. This encompasses the actions they are permitted
              to take on behalf of the grantor regarding the vehicle. Common
              powers may include but are not limited to:
            </p>
            <ul>
              <li>Registering the vehicle</li>
              <li>Renewing registration and license plates</li>
              <li>Transferring ownership</li>
              <li>Obtaining a duplicate title or registration</li>
              <li>
                Handling vehicle-related transactions with governmental agencies
                or private entities
              </li>
            </ul>
          </li>
          <li>
            <p>
              <span className="font-semibold text-stone-200">
                Duration and Revocation:
              </span>{" "}
              The duration of the Power of Attorney is established, delineating
              when it becomes effective and whether it remains in effect
              indefinitely or expires after a specific period. Additionally,
              provisions for revoking the Power of Attorney may be outlined,
              specifying the circumstances under which the grantor can revoke
              the Attorney-in-Fact's authority.
            </p>
          </li>
          <li>
            <p>
              <span className="font-semibold text-stone-200">
                Signatures and Witnesses:
              </span>{" "}
              Both the grantor and the Attorney-in-Fact must sign the document
              to make it legally binding. Depending on jurisdictional
              requirements, witnesses and notarization may also be necessary for
              validity.
            </p>
          </li>
        </ol>
        <p>
          <span className="font-semibold text-stone-200">
            Legal Considerations:
          </span>
        </p>
        <ol className="pl-6 list-decimal">
          <li>
            <p>
              <span className="font-semibold text-stone-200">
                Jurisdictional Compliance:
              </span>{" "}
              It's crucial to ensure that the Power of Attorney adheres to the
              laws and regulations of the jurisdiction where the vehicle is
              registered and any transactions are to occur.
            </p>
          </li>
          <li>
            <p>
              <span className="font-semibold text-stone-200">
                Clarity and Specificity:
              </span>{" "}
              The language used in the document should be clear and unambiguous,
              outlining the precise powers delegated to the Attorney-in-Fact to
              avoid misunderstandings or disputes.
            </p>
          </li>
          <li>
            <p>
              <span className="font-semibold text-stone-200">
                Legal Advice:
              </span>{" "}
              As legal matters can be complex and vary by jurisdiction, it's
              advisable for both the grantor and the Attorney-in-Fact to seek
              legal counsel to ensure the document meets all legal requirements
              and adequately protects the interests of all parties involved.
            </p>
          </li>
        </ol>
        <p>
          This Power of Attorney for a vehicle serves as a vital tool for
          delegating authority and streamlining the management of
          vehicle-related affairs, providing both convenience and peace of mind
          for the grantor and the Attorney-in-Fact.
        </p>
        <p className="font-semibold text-stone-200">
          By digitally signing in the box below, you (The Grantor) allow POSHIT
          Inc. (Attorney-in-Fact) to generate a Power of Attorney document as
          described above for the vehicle (VIN: {vehicle.vin}). This document
          will then be notarized by POSHIT Inc. and used to conduct
          vehicle-related transactions on your behalf.
        </p>
      </div>
    </div>
  );
}
