import StatsPair from "../UI/StatsPair";
import { useContext } from "react";
import DataContext from "../../context/data-context";

export default function Stats({ revenue }) {
  const { vehicles, partner } = useContext(DataContext);
  const stats = {
    revenue: 0,
    vehicles: vehicles.length,
    received: 0,
    monthly: 0,
  };
  revenue?.forEach((month) => {
    if (month) stats.revenue += Number(month.revenue);
  });
  partner.payments?.forEach((payment) => {
    if (payment.status === "succeeded") stats.received += Number(payment.amount);
  });
  vehicles?.forEach(vehicle => {
    if (vehicle.monthly) stats.monthly += Number(vehicle.monthly);
  })
  return (
    <div className="grid md:grid-cols-4 gap-y-10">
      <StatsPair title="Revenue" value={`$${stats.revenue?.toFixed(2)}`} />
      <StatsPair title="Payments Received" value={`$${Number(stats.received).toFixed(2)}`} />
      <StatsPair title="Vehicles" value={stats.vehicles} />
      <StatsPair title="Monthly Installments" value={`$${stats.monthly.toFixed(2)}`} />
    </div>
  );
}
