import { useState, useEffect, createContext } from "react";
import { auth } from "../firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

export const AuthContext = createContext({
  user: {},
  loadingUser: true,
  error: {},
  login: () => {},
  logout: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoadingUser(false);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        setUser(user);
      }
      setLoadingUser(false);
    });
    return unsubscribe;
  }, []);

  const login = async (email, password) => {
    try {
      setError();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, error, loadingUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
