import { FaListAlt } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import { GoSettings } from "react-icons/go";
import { useNavigate } from "react-router-dom";

export default function NavigationBar({ selected }) {
  const navigate = useNavigate();
  return (
    <div className="grid items-center grid-cols-4 p-4 box-bg justify-items-center rounded-b-xl md:hidden bg-stone-900">
      <div onClick={() => navigate("/general")}>
        <FaListAlt
          className={`w-6 h-6 ${
            selected === "General" ? "text-yellow-600" : "text-stone-400"
          }`}
        />
      </div>

      <div onClick={() => navigate("/vehicles")}>
        <FaCar
          className={`w-6 h-6 ${
            selected === "Booking" ? "text-yellow-600" : "text-stone-400"
          }`}
        />
      </div>
      <div onClick={() => navigate("/payments")}>
        <MdPayments
          className={`w-6 h-6 ${
            selected === "Subscription" ? "text-yellow-600" : "text-stone-400"
          }`}
        />
      </div>
      <div onClick={() => navigate("/settings")}>
        <GoSettings
          className={`w-6 h-6 ${
            selected === "Settings" ? "text-yellow-600" : "text-stone-400"
          }`}
        />
      </div>
    </div>
  );
}
