import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

export default function SignaturePad({ setSignature }) {
  const sigCanvas = useRef();

  const createSignature = () => {
    try {
      if (sigCanvas.current.isEmpty()) throw new Error("Please sign first");
      const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("base64");
      setSignature(URL);
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return (
    <div className="grid gap-4">
      <SignatureCanvas
        penColor="black"
        canvasProps={{
          width: "full",
          height: "full",
          className:
            "border-2 border-yellow-600 mx-auto rounded-xl bg-stone-400",
        }}
        ref={sigCanvas}
      />
      <div className="grid gap-4">
        <div
          className="btn-secondary"
          onClick={() => sigCanvas.current.clear()}
        >
          Clear
        </div>
        <div className="btn-primary" onClick={createSignature}>
          Confirm
        </div>
      </div>
    </div>
  );
}
