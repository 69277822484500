export default function Stepper({ progress, steps, stepHandler }) {

    // Join Class Names
    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }
  
    // Create Stepper Component
    let stepper = [...Array(steps)].map((e, i) => (
      <div className="flex items-center h-14" key={i}>
        <div
          onClick={() => {}}
          className={classNames(
            "rounded-full flex cursor-not-allowed items-center justify-center h-10 w-10 md:h-12 md:w-12 transition-all ring-yellow-600 duration-500",
            progress > i
              ? "bg-yellow-600 hover:ring-2"
              : "bg-gray-300 dark:bg-stone-400"
          )}
        >
          <p className="text-3xl font-bold text-white font-Play">{i + 1}</p>
        </div>
        {i < steps - 1 && (
          <div className="w-12 h-1 bg-gray-300 md:w-24 dark:bg-stone-400">
            <div
              key={i}
              className={classNames(
                "bg-yellow-600 h-1 flex justify-center items-center transition-all duration-1000",
                progress > i ? "w-full" : "w-0"
              )}
            ></div>
          </div>
        )}
      </div>
    ));
  
    // Return Stepper Component
    return <div className="flex justify-center py-4">{stepper}</div>;
  }
  