export default function PartnerAgreement() {
  return (
    <div className="grid gap-4 text-sm text-stone-400">
      <p className="italic">
        Thank you for your interest in being POSH&apos;s Financial Partner!
      </p>
      <p className="font-semibold text-stone-200">
        PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT
        INFORMATION THAT AFFECTS YOUR RIGHTS, REMEDIES, AND OBLIGATIONS. THEY
        INCLUDE AN AGREEMENT TO ARBITRATE (UNLESS YOU OPT OUT). THESE TERMS ALSO
        INCLUDE A PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS AND
        NON-INDIVIDUALIZED RELIEF FOR ALL MATTERS IN EITHER COURT OR
        ARBITRATION, VARIOUS LIMITATIONS AND EXCLUSIONS, A CLAUSE THAT GOVERNS
        THE JURISDICTION, VENUE, AND GOVERNING LAW OF DISPUTES, EXCEPT WHERE
        PROHIBITED, AND OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND
        REGULATIONS
      </p>
      <p>
        POSHit Inc. (collectively, “POSHcars”, “POSH”, or “us”), is a
        decentralized fleet management company that provides an online car
        subscription platform to our subscribers. POSH’ service is accessible
        online including at https://POSHcars.io/ and as an application for
        mobile devices. The POSH websites, blog, mobile applications, and
        associated services are collectively referred to as “the Services”. By
        agreeing to list your cars with POSH, including by communicating with
        us, you agree to comply with, and be legally bound by, the provisions of
        these Terms of Service (these “Terms”).
      </p>
      <p>
        These Terms govern your rights and responsibility and constitute a
        binding legal agreement between you and POSH. Eligibility, registration,
        verification Eligibility POSH looks for high quality Partners to entrust
        their vehicles to our custodianship. Partners can list vehicles they
        already own or work with POSH to determine which vehicles are in demand,
        purchase, and list the vehicles on our platforms.
      </p>
      <p className="font-bold text-stone-200">
        POSH reserves the sole right to set up qualifications for the vehicles
        and determine if Partners’ vehicles meet the requirements to be listed
        on our platforms.
      </p>
      <p>These qualifications include but are not limited to:</p>
      <div className="grid gap-2">
        <p>
          Be an eligible passenger vehicle located in United States, registered
          to the Partners or to an owner who’s authorized the Partners to list
          their vehicle, and meets all state legal requirements
        </p>
        <p>
          Ineligible vehicles include, but aren’t limited to, motorcycles,
          limousines, box trucks, vehicles that seat more than nine (including
          the driver), RVs, vehicles that require a commercial license, off-road
          vehicles
        </p>
        <p>
          Have permanent or temporary or similar plates; we don’t allow dealer
          or trader plates
        </p>
        <p>Be no more than 6 years old or 50k miles at time of listing</p>
        <p>Have fair market value of up to $200,000</p>
        <p>
          (New York Only) Have an inspection sticker valid through at least the
          following month
        </p>
        <p>
          Have a clean (e.g. not a “branded” or “salvage”) title. You must
          produce a recent Carfax for the vehicle before it can be listed with
          POSH.
        </p>
        <p>Have never been declared a total loss</p>
        <p>Vehicles must have extended warranty</p>
        <p>Vehicles must have prepaid maintenance</p>
        <p>Be deemed attractive and in high demand with our clientele</p>
        <p>
          POSH reserves the right to modify eligibility requirements at any time
          for any reason
        </p>
        <p>
          Financial Partner also have to meet the following requirements in
          order to participate in our Partner Program:
        </p>
        <p>Must have a great driving record Must be 23 years or older</p>
        <p>Must be US citizen or permanent resident</p>
        <p>Must be employed</p>
        <p>Must have exceptional credit score</p>
        <p>Have a clean record. No criminal history.</p>
        <p>Able to invest and cover down payments, initial costs on cars</p>
        <p>Must consent to all POSH’ terms</p>
        <p>
          Must allow POSH to manage the asset class fully without any
          restrictions
        </p>
        <p>Must agree to a 60 month term minimum</p>
      </div>
      <p className="font-semibold text-stone-200">Vehicle Registeration</p>
      <p>
        When you entrust your vehicle to POSH as a Financial Partner, you must
        provide us with accurate, current, and complete information about
        yourself including first and last name, email address, address, phone
        number, and a payment method. You may need to create a Financial Partner
        account on our Partner portal to keep track of the vehicle’s performance
        and earnings.
      </p>
      <p>
        If you work with POSH to acquire vehicles, you consent to allow us and
        our financial institute, car dealership contacts to check your credit
        scores and ability to take out loans under your name (if applicable). If
        you are not approved for the loans, POSH reserves the right to deny your
        participation as a Financial Partner unless other arrangements can be
        made. You agree to make timely monthly payments toward the car loan to
        the financer on the agreed upon schedule and keep the loan in good
        standing. If you fail to keep up with your vehicle payment, POSH and its
        service providers will employ all legal methods available to collect
        amounts due, including the engagement of collection agencies or legal
        counsel. You agree to allow POSH’ access to the car payment profile.
        When you provide a payment method, you authorize POSH, or third-party
        service providers acting on behalf of POSH, to store your payment
        credential for future use.
      </p>
      <p className="font-semibold text-stone-200">Partner's Verification</p>
      <p>
        Where permitted, POSH has the right, but not the obligation, to
        undertake screenings, checks, and engage in processes designed to (1)
        help verify the identities or check the backgrounds of the Financial
        Partner, including driving history and driver’s license validity and (2)
        verify vehicle details. POSH does not endorse any user, or a user’s
        background, or commit to undertake any specific screening process. POSH
        may in its sole discretion use third-party services to verify the
        information you provide to us and to obtain additional related
        information and corrections where applicable, and you hereby authorize
        POSH to request, receive, use, and store such information. POSH may
        permit or refuse your request to list a vehicle in its sole and absolute
        discretion.
      </p>
      <p className="font-semibold text-stone-200">Partner's Commitments</p>
      <p>
        You agree that you will allow POSH to list your cars on our POSH
        platforms and third-party platforms as seen fit by POSH. Each platform
        may have different requirements regarding the cars and documentation.
        You agree to provide such information to us as requested.
      </p>
      <p className="italic font-semibold">
        If a financial partner handles cars themselves and lists their cars on
        POSH platform for the sole purpose to connect with subscribers:
      </p>
      <p>
        If you are given access to POSH’ and/or a third-party platform account,
        you agree to always use your Accounts and the Services in compliance
        with platforms’ Terms, applicable law, and any other policies and
        standards provided to you by that platform. You are, and will be solely
        responsible for, all of the activity that occurs through your platforms’
        Accounts. Keep your platforms’ Accounts’ information, including your
        password, secure. You agree that you will not disclose your password to
        any third party and that you will take sole responsibility for any
        activities or actions under your platforms’ Accounts, whether or not you
        have authorized such activities or actions. You will immediately notify
        POSH of any actual or suspected unauthorized use of your Accounts. We
        are not responsible for your failure to comply with this clause, or for
        any delay in shutting down or protecting your Accounts unless you have
        reported unauthorized access to us.
      </p>
      <p className="italic font-semibold">
        If the financial partner does not handle cars and just list their cars
        on POSH platform
      </p>
      <p>
        You agree to let POSH handle the operations of the car subscription at
        our sole discretion, including price point, trip duration, trip number,
        millage, reviews, featback, photos, description of the vehicles, or
        trips, at POSH or other third-party platforms.
      </p>
      <p className="font-semibold text-stone-200">Licensed Content</p>
      <p>
        Subject to your compliance with the provisions of these Terms, POSH
        grants you a limited, revocable, non-exclusive, non-transferable
        license, to access and view any POSH and/or user content to which you
        are permitted access, solely for your personal and non-commercial
        purposes. You have no right to sublicense the license rights granted in
        this section. No licenses or rights are granted to you by implication or
        otherwise under any intellectual property rights owned or controlled by
        POSH or its licensors, except for the licenses and rights expressly
        granted in these Terms.
      </p>
      <p className="font-semibold text-stone-200">User Content</p>
      <p>
        We may, in our sole discretion, permit you to post, upload, publish,
        submit or transmit content through the Services such as photographs of
        you and your vehicle(s), reviews, feedback, and descriptions of you,
        your vehicle, or trip. By sharing or making available any content on or
        through the Services, or through POSH promotional campaigns, you grant
        POSH a worldwide, irrevocable, perpetual (or for the term of the
        protection), non-exclusive, transferable, royalty-free license, with the
        right to sublicense, to use, view, copy, adapt, modify, distribute,
        transfer, publicly display, publicly perform, transmit, stream,
        broadcast, access, view, and otherwise exploit such content on, through,
        by means of, or to promote or market the Services. Except as described
        above, POSH does not claim any ownership rights in any such content and
        nothing in these Terms will be deemed to restrict any rights that you
        may have to use and exploit any such content.
      </p>
      <p className="font-semibold text-stone-200">Prohibited Activities</p>
      <p>
        In connection with your use of or access to the Services, you agree that
        you will not, nor advocate, encourage, request, or assist any third
        party to:
      </p>
      <p>Violate any law, including:</p>
      <p>
        Breach, violate, and/or circumvent any local, state,
        provincial/territorial, regional, or national law or other law or
        regulation, or any order of a court, including, without limitation,
        airport regulations and tax regulations, licensing or registration
        requirements, or third-party rights
      </p>
      <p>Post false, inaccurate, misleading, defamatory, or libelous content</p>
      <p>
        Infringe, reproduce, perform, display, distribute, reverse engineer, or
        prepare derivative works from content that belongs to or is licensed to
        POSH, or that comes from the Services and belongs to another POSH user
        or to a third party, including works covered by any copyrights,
        trademark, patent, or other intellectual property, privacy, publicity,
        moral, or contractual rights, except with prior express written
        permission of POSH
      </p>
      <p>
        Dilute, tarnish, or otherwise harm the POSH brand in any way, including:
      </p>
      <p>
        1. Through unauthorized use of the Services and/or user content <br />
        2. Registering and/or using "POSH" or derivative terms in domain names,
        trade names, trademarks, or otherwise
        <br />
        3. Registering and/or using domain names, trade names, trademarks,
        social media account names, or other means of identification that
        closely imitate or are confusingly similar to POSH domains, trademarks,
        taglines, promotional campaigns, or POSH and/or user content
      </p>
      <p>Provide or submit any false or misleading information, including:</p>
      <p>
        1. False name, date of birth, driver’s license details, payment method,
        insurance, or other personal information
        <br />
        2. In relation to a claim (for example about damage to a vehicle)
        <br />
        3. Impersonating any person or entity, or falsifying or otherwise
        misrepresenting yourself or your affiliation with any person or entity
      </p>
      <p>Fail to honor your commitments, including:</p>
      <p>
        1. Fail to pay fees, penalties, or other amounts owed to POSH or another
        financial, government institutions that may interfere with POSH’s
        operation including our use of the vehicles
        <br />
        2. Fail, as a hands-on financial partner, to timely deliver, make
        available, or return any vehicle and optional Extras, unless you have a
        valid reason.
        <br />
        3. Use the Services to find a host or guest, and then complete a
        transaction partially or wholly independent of the Services, for any
        reason including but not limited to circumventing the obligation to pay
        any fees related to the provision of the Services by POSH (aka, gray
        market transactions, which do not necessarily require the exchange of
        money)
        <br />
        4. Transfer your third-party platform Account and/or user ID to another
        party without our consent.
        <br />
        5. Leave a vehicle unlocked or running with the keys inside, except
        where instructed to do so directly by POSH in certain limited
        circumstances
      </p>
      <p>Harm or threaten to harm users of our community, including:</p>
      <p>
        1. Harass, stalk, or defame any other financial partners, subscribers,
        POSH employees, or collect or store any personally identifiable
        information about any other financial partners, subscribers, POSH
        employees other than for purposes of transacting car subscription
        dealings.
        <br />
        2. Engage in physically or verbally abusive or threatening conduct
        <br />
        3. Use the Services to transmit, distribute, post, or submit any
        information concerning any other person or entity, including without
        limitation, photographs of others without their permission, personal
        contact information, payment method details, or account numbers
        <br />
        4. Treat anyone differently based on the way they look, who they love,
        what they believe, how they self-identify, where they are from, or when
        they were born. Discrimination of any kind is not tolerated in the POSH
        community
        <br />
        5. Sue or assert legal claims against POSH or financial partners,
        subscribers, POSH employees in any manner prohibited or waived by these
        Terms
      </p>
      <p>Use the Services for your own unrelated purposes, including:</p>
      <p>
        1. Contact other financial partners, subscribers, POSH employees for any
        purpose other than in relation to a booking, vehicle, listing, or the
        use of the Services by such community members.
        <br />
        2. Commercialize any content found on the Services or software
        associated with the Services, including reviews
        <br />
        3. Harvest or otherwise collect information about users without their
        and our consent
        <br />
        4. Recruit or otherwise solicit any subscriber to join third-party
        services or websites that are competitive to POSH, without our prior
        written approval
      </p>
      <p>Interfere with the operation of the Services, including by:</p>
      <p>
        1. Interfering with any other financial partner’s or POSH’ listings
        <br />
        2. Using the Services in connection with the distribution or posting of
        unsolicited commercial messages (e.g., spam)
        <br />
        3. Distributing viruses or any other technologies such as cancel bots,
        Trojan horses, harmful code, flood pings, denial-of-service attacks,
        backdoors, packet or IP spoofing, forged routing or e-mail address
        information, or similar methods or technology that may disrupt or
        interfere with the operation or provision of the Services, or harm POSH
        or the interests or property of others
        <br />
        4. Bypassing robot exclusion headers, interfering with the working of
        the Services, or imposing an unreasonable or disproportionately large
        load on our infrastructure
        <br />
        5. Systematically retrieving data or other content from the Services to
        create or compile, directly or indirectly, a collection, compilation,
        database, directory, or the like, whether by manual methods, or through
        the use of bots, crawlers, spiders, or otherwise
        <br />
        6. Using, displaying, mirroring, or framing the Services or any
        individual element within the Services, the POSH name, any POSH
        trademark, logo, or other proprietary information, or the layout and
        design of any page or form contained on a page in the Services, without
        the express written consent of POSH
        <br />
        7. Accessing, tampering with, or using non-public areas of the Services,
        our computer systems, or the technical delivery systems of our service
        providers
        <br />
        8. Attempting to probe, scan, or test the vulnerability of any of our
        system or network or breach any security or authentication measures
        <br />
        9. Avoiding, bypassing, removing, deactivating, impairing, descrambling,
        or otherwise circumventing any technological measure implemented by POSH
        or any of our service providers or any other third party (including
        another user) to protect the Services
        <br />
        10. Forging any TCP/IP packet header or any part of the header
        information in any email or newsgroup posting, or in any way using the
        Services to send altered, deceptive, or false source-identifying
        information
        <br />
        11. Attempting to decipher, decompile, disassemble, or reverse engineer
        any of the software used to provide the Services
      </p>
      <p className="font-semibold text-stone-200">
        Violation of this Agreement
      </p>
      <p>
        POSH has the right, but not the obligation, to investigate, pursue, and
        seek to prosecute, litigate, or refer to law enforcement, violations of
        the Agreement to the fullest extent permissible by the law.
      </p>
      <p>
        POSH reserves the right, at any time and without prior notice, in
        accordance with applicable law, to remove or disable access to any
        content that POSH, at its sole discretion, considers to be objectionable
        for any reason, in violation of these Terms, or otherwise harmful to the
        Services or our community. If we believe you are abusing POSH, our
        subscribers, or any other person in any way or violating the letter or
        spirit of any of these Terms, we may, in our sole discretion and without
        limiting other remedies, limit, suspend, or terminate your access to the
        Services, financial agreements, remove hosted content, deny a damage
        claim, remove or demote your listings, reduce or eliminate any
        discounts, and take technical and/or legal steps to prevent you from
        using the Services. Additionally, we reserve the right to refuse or
        terminate access to the Services to anyone for any reason at our
        discretion to the full extent permitted under applicable law.
      </p>
      <p className="font-semibold text-stone-200">
        Enforement of POSH's Policies
      </p>
      <p>
        When an issue arises, we may consider the user’s performance history and
        the specific circumstances in applying our Policies. We may choose to be
        more lenient with policy enforcement in an effort to do the right thing,
        subject to our sole and absolute discretion.
      </p>
      <p className="font-semibold text-seton-200">
        Communication between POSH and Partners
      </p>
      <p>
        You agree that POSH may contact you by electronic means (e.g.,
        electronic mail; notifications via POSH messaging; app notification) in
        lieu of any requirement for mailed notices. To contact you more
        efficiently, you agree that we may at times also contact you using
        autodialed or prerecorded message calls or text messages at your phone
        number(s). We may place such calls or texts primarily to confirm your
        signup, provide notices regarding your POSH agreement or POSH activity,
        investigate or prevent fraud, collect a debt owed to us, or communicate
        urgent messages. We may share your phone number(s) with service
        providers with whom we contract to assist us in pursuing these
        interests. We will not share your phone number(s) with third parties for
        their own purposes without your consent. Standard telephone minute and
        text and data charges may apply. Where POSH is required to obtain your
        consent for such communications, you may choose to revoke your consent.
      </p>
      <p>
        You authorize POSH and its service providers, without further notice or
        warning and in our discretion, to monitor or record telephone
        conversations you or anyone acting on your behalf has with POSH or its
        agents for quality control and training purposes. You acknowledge and
        understand that your communications with POSH may be overheard,
        monitored, or recorded without further notice or warning.
      </p>
      <p className="font-semibold text-stone-200">Non-disparagement</p>
      <p>
        The Parties agree that they will not take any action that will harm the
        reputation of the other Party, or which would reasonably be expected to
        lead to unwanted or unfavorable publicity to either of the other Party.
      </p>
      <p className="font-semibold text-stone-200">
        Insurance & Protection Plans
      </p>
      <p>
        POSH is not an insurance company and does not insure financial partners
        or subscribers. Financial partners or subscribers protection plans made
        available through the Services are in no way related. To be eligible for
        the benefits of a protection plan, Financial partners or subscribers
        must comply with these Terms. Protection plans are available through the
        Services only in the United States.
      </p>
      <p className="font-semibold text-stone-200">Auto Liability Insurance</p>{" "}
      <p>
        POSH has partnered with a Third Party for comprehensive insurance
        coverage under an automobile liability insurance policy. This policy
        covers subscribers who drive the vehicles and POSH’ employees handling
        the vehicles for POSH’ business purposes.
      </p>
      <p>
        If the subscriber has their own personal auto policy, it will be primary
        over the POSH protection depending on various factors such as applicable
        laws, where the subscriber books the vehicle, and/or where the accident
        or damages occur. Terms and exclusions apply.
      </p>
      <p className="font-semibold text-stone-200">
        Maintenance of Partner's Vehicles
      </p>
      <p>
        POSH will be responsible for the maintenance and repair of the vehicle
        while it is under POSH’ car and contract. This does not contradict with
        POSH’ requirement that all vehicles must have prepaid maintenance and
        extended warranty with the dealership which you may be financially
        responsible for at the purchase of the vehicle.
      </p>
      <p className="font-semibold text-stone-200">
        POSH’s Responsibility Toward Vehicles
      </p>
      <p>
        While in POSH’ possession, POSH will be responsible for the safety,
        roadworthiness, or legal status (e.g., whether the vehicle is legally
        registered or the subject of a stolen vehicle report) of your vehicles,
        as well as ensuring the vehicles are in safe and operable condition,
        legally registered to be driven on public roads, not subject to a
        missing or stolen vehicle report, have a clean title (e.g.,
        non-salvaged/non-branded/non-washed/non-written off), not subject to any
        applicable safety recalls, and otherwise satisfy our eligibility
        requirements.
      </p>
      <p className="font-semibold text-stone-200">
        Termination of Partner's Contract
      </p>
      <p>
        Financial partners acknowledge that POSH has requirements for vehicles
        that are eligible to be listed on our platforms and operations. If at
        any moment, POSH deems your vehicles are not safe and in operable
        condition, POSH reserves the right to delist your vehicles. If the
        vehicle is considered to be no longer appropriate to be listed on our
        Services, POSH will give you termination notice 90 days in advance and
        will continue to pay you the agreed upon monthly payment for the vehicle
        during this period. After 90 days, any contract and POSH’ obligations
        concerning this vehicle will be terminated. If POSH is unable to fulfill
        its obligations to you after 30 days, the contract is considered
        terminated. You can withdraw the vehicle from POSH’ possession and
        request that they no longer be listed with POSH services.
      </p>
      <p className="font-semibold text-stone-200">
        Financial Partner's Commitments (continued)
      </p>
      <p>
        As a financial partner, you commit that you will provide a safe and
        legally registered vehicle, with current license plates, with a clean
        (non-salvage/branded/written off) title, and in good mechanical
        condition. You will not offer any vehicle or optional Extra that you do
        not yourself own or have authority to share or that may not be shared
        for compensation pursuant to the terms and conditions of any agreement
        with a third party, including, but not limited to, a lease or financing
        agreement. If your vehicle is subject to a lease, loan, or other
        financing agreement, you must confirm sharing your vehicle on POSH does
        not violate the terms of the contract with the lienholder. You will not
        offer any Extra that is not safe, clean, and acceptable for the use it
        is intended. You will not offer any vehicle that is the subject of a
        missing or stolen vehicle report. You will not offer any vehicle that is
        the subject of a safety recall without first properly addressing the
        matter subject to the recall. You will not offer a vehicle that is not
        roadworthy (i.e., not “street legal”) in the location where it is shared
        and it will not have any illegal modifications to any part of the
        vehicle. You will remove any firearms or other weapons from your vehicle
        prior to providing it to a guest. You will repay loans related to your
        POSH business on time and in full.
      </p>
      <p>
        If you are a hands-on financial partner, you will provide your vehicle
        on time but only to a subscriber who is listed on the Services as an
        Approved Driver for the trip. You commit that your listings will be
        complete and accurate and you will honor all representations made in
        your listings, including honoring the price quoted to a guest. You will
        not cancel a booking for the purpose of seeking a higher price from a
        guest.
      </p>
      <p className="font-semibold text-stone-200">
        Information Given at Registeration
      </p>
      <p>
        When you enter into a car agreement with POSH, you and POSH will
        identify passenger vehicle(s) that you can list for sharing through the
        Services. Subjected to POSH’ consent, you may choose to be a hands-on
        financial partner where you are actively involved with handling car
        delivery, booking, listing, maintenance, …. You may only use the
        Services in connection with vehicles that you own or otherwise have all
        the necessary rights and permissions to share for compensation. In case
        you choose to be a hands-off financial partner where POSH will handle
        the operations of your car listings. Should you choose to be a hands-off
        financial partner, you agree to allow POSH to list your vehicles at our
        sole discretion including but not limited to price point, booking,
        delivery and maintenance.
      </p>
      <p className="font-semibold text-stone-200">Photographs of Vehicles</p>
      <p>
        POSH may offer financial partners the option of having photographers
        take photographs of their vehicles and/or financial partners with their
        vehicles (“Images”). You agree that POSH is the sole and exclusive owner
        - or exclusive licensee, as allowed by applicable law - of all right,
        title, and interest in all copyrights, trademark rights, and any and all
        other intellectual property rights, including right of publicity,
        worldwide, in the Images regardless of whether they are included in the
        listing, and you shall take no action to challenge or object to the
        validity of such rights or POSH’ ownership or registration thereof. You
        acknowledge that POSH may use the Images for advertising, marketing,
        commercial, and other business purposes in any media or platform,
        whether in relation to your listing or otherwise, without further notice
        or compensation. Further, you waive any and all rights to royalties or
        moral rights you may have in the Images. If you use the POSH photography
        program, you agree that you will not use the Images in connection with
        sharing your vehicle on any platform, website, or application other than
        explicitly allowed by POSH. At POSH’ request, you will execute documents
        and take such further acts as Poah may reasonably request to assist POSH
        to acquire, perfect, and maintain its intellectual property rights and
        other legal protection in the Images.
      </p>
      <p className="font-semibold text-stone-200">EARNINGS</p>
      <p>
        POSH guarantees a minimum earning equivalent to the monthly payment of
        the vehicle plus 40% of the leftover profit for the month, after
        deducting any fees or reimbursement payable to POSH. The first payment
        will occur 30 days after the vehicle is live on our platform.
      </p>
      <p className="font-semibold text-stone-200">PAYMENT PROCESSING</p>
      <p>
        We have partnered up with a Third Party to provide payment processing
        services to our financial partners. Such services are subjected to the
        Third Party Account agreement. You agree to be bound by the Third Party
        Terms, which may be modified from time to time. As a condition of Posh
        enabling payment processing services through the Third Party, you
        authorize Posh to obtain all necessary access and perform all necessary
        activity on your Third Party Account to facilitate payment. You further
        agree to provide accurate and complete information about you, and
        authorize Posh to share it and transaction information with the Third
        Party for the purposes of facilitating the payment processing services
        provided by the Third Party. Posh reserves the right to switch payment
        processing vendors at its discretion.
      </p>
      <p className="font-semibold text-stone-200">Dispute Resolution</p>
      <p>
        Pre-Dispute Resolution Procedure:
        <br />
        <br />
        For the purposes of this Section, “POSH Party" or "POSH Parties” shall
        mean POSH and POSH's service providers and licensors, and their
        respective officers, directors, employees, contractors and agents when
        providing services for/on behalf of POSH. Before asserting a claim in
        any proceeding (including in an individual arbitration or in a small
        claims proceeding), you and the applicable POSH Party agree that each of
        you shall give the other party written notice of the claim to be
        asserted 30 days before initiating a proceeding and make a reasonable
        good faith effort to resolve the claim. If you are intending to assert a
        claim against a POSH Party, you must send the written notice of the
        claim to 100 Tradecenter Drive, Woburn, MA. If POSH is intending to
        assert a claim against you, we will send the written notice of the claim
        to you at your address appearing in our records. NO SETTLEMENT DEMAND OR
        SETTLEMENT OFFER USED IN THIS PRE-DISPUTE RESOLUTION PROCESS MAY BE USED
        IN ANY PROCEEDING, INCLUDING AS EVIDENCE OR AS AN ADMISSION OF ANY
        LIABILITY OR DAMAGES (OR LACK THEREOF).
      </p>
      <p>
        Agreement to Arbitrate.
        <br />
        <br />
        In the event of a dispute that cannot be resolved informally through the
        pre-dispute resolution procedure, you and the POSH Parties agree to
        arbitrate all disputes and claims arising under or relating to any of
        these Terms, POSH's vehicles, POSH's services, or any other transaction
        involving you and POSH, whether in contract, warranty,
        misrepresentation, fraud, tort, intentional tort, statute, regulation,
        ordinance, or any other legal or equitable basis. The interpretation and
        scope of this provision, and the arbitrability of the dispute or claim,
        are for the arbitrator to decide.
      </p>
      <p>
        What is Arbitration?
        <br />
        <br />
        Arbitration is more informal than a lawsuit. Arbitration uses a neutral
        arbitrator instead of a judge or jury, allows for more limited discovery
        than in court, and is subject to very limited review by courts. Under
        this dispute resolution provision, arbitrators can award the same
        individual relief affecting individual parties that a court can award,
        including damages and an award of attorneys' fees, if the applicable law
        allows. BY AGREEING TO RESOLVE DISPUTES THROUGH ARBITRATION, YOU AND
        POSH AGREE TO EACH UNCONDITIONALLY WAIVE THE RIGHT TO A TRIAL BY JURY OR
        COURT.
      </p>
      <p>
        Class Action Waiver.
        <br />
        <br />
        YOU AND THE POSH PARTIES AGREE THAT ANY CLAIMS BROUGHT BY YOU OR THE
        POSH PARTIES WILL BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY AND
        WILL NOT BE BROUGHT AS A CLASS, REPRESENTATIVE, COLLECTIVE OR PRIVATE
        ATTORNEY GENERAL ACTION. The arbitrator may not consolidate more than
        one person's claims and may not otherwise preside over any form of a
        class, representative, or private attorney general proceeding. You and
        the POSH Parties agree to seek only such relief-whether in the form of
        damages, an injunction, or other non-monetary relief-as is necessary to
        resolve any individual injury that either you or POSH have suffered or
        may suffer. In particular, if either you or a POSH Party seek
        non-monetary relief, such relief must be individualized and may not
        affect individuals or entities other than you or the applicable POSH
        Party. This requirement that claims be brought in binding arbitration
        only in an individual capacity and not as a representative, private
        attorney general, or class Subscriber ("Class Action Waiver") is
        non-severable. If the Class Action Waiver is found to be unenforceable,
        then the entirety of this dispute resolution provision shall be null and
        void.
      </p>
      <p>
        Applicable Law and Rules. The Federal Arbitration Act governs the
        interpretation and enforcement of this dispute resolution provision. The
        arbitration shall be conducted before one commercial arbitrator with
        substantial experience in resolving commercial disputes from the
        American Arbitration Association ("AAA"). As modified by this Agreement,
        and unless otherwise agreed upon by the parties in writing, the
        arbitration will be governed by the AAA's rules for commercial
        arbitration and, if the arbitrator deems them applicable, the procedures
        for consumer-related disputes. For more information on AAA, its rules
        and procedures, and how to file an arbitration claim, you may call AAA
        at 800-778-7879 or visit the AAA website at http://www.adr.org.
      </p>
    </div>
  );
}
