export default function VehicleAgreement({ vehicle }) {
  return (
    <div className="grid gap-4 text-sm text-stone-400">
      <p className="italic">
        Thank you for adding a vehicle to our fleet. We have approved your
        submission and will begin doing business with you.
      </p>
      <p className="font-semibold text-stone-200">
        PLEASE READ THE FOLLOWING CAREFULLY AND SIGN AT THE BOTTOM OF THE PAGE.
      </p>
      <p>
        This Notes Payable Agreement for your {vehicle.year} {vehicle.make}{" "}
        {vehicle.model} is designed to outline the terms and conditions of the
        contract between you, the owner - referred to as the "Lessor", and us,
        POSHIT Inc - referred to as the "Lessee".
      </p>
      <p className="font-semibold text-stone-200">KEY COMPONENTS:</p>
      <p className="text-stone-200">Term</p>
      <p>
        The term of this Agreement shall be for 60 months, commencing on the
        Date Signed. Both parties reserve the right terminate this agreement on
        the following conditions:{" "}
      </p>
      <p className="pl-4">
        1. Either party needs to provide a 60 days written notice prior to
        initiating the termination of this agreement
        <br />
        2. In case the Lessor terminates the agreement, the Lessee shall return
        the vehicle to the Lessor and revoke any Power of Attorneys. The Lessee
        will also not be responsible for any future notes payable to the Lessor
        <br />
        3. In case the Lessee terminates the agreement, the Lessor shall be
        entitled to accelerate the notes payable and demand payment of the
        entire remaining balance.
      </p>
      <p className="text-stone-200">Rental Payment</p>
      <p>
        Lessee shall pay Lessor for the use of the vehicle on a notes payable
        basis. The amount of the notes payable shall be the Amount mentioned
        above per month payable every month over the course of a total of 60
        months, due on the 1st day of each month.
      </p>
      <p className="text-stone-200">Maintenance and Repair</p>
      <p>
        Lessee shall be responsible for the routine maintenance and repair of
        the vehicle during the term of the lease. Lessee shall keep the vehicle
        in good condition and shall return it in the same condition as when it
        was received, reasonable wear and tear excepted.
      </p>
      <p className="text-stone-200">Insurance</p>
      <p>
        Lessee shall obtain and maintain liability insurance on the vehicle for
        the duration of the lease term. Lessor shall be named as an additional
        insured on the insurance policy.
      </p>
      <p className="text-stone-200">Governing Law</p>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of the State of Delaware. Entire Agreement This Agreement
        constitutes the entire agreement between the parties and supersedes all
        prior negotiations, understandings, and agreements between the parties.
      </p>
      <p className="text-stone-200">Binding Effect</p>
      <p>
        This Agreement shall be binding upon and ensure to the benefit of the
        parties hereto and their respective heirs, executors, administrators,
        successors, and assigns.
      </p>
    </div>
  );
}
