import {AiFillInstagram} from 'react-icons/ai';
import {AiFillFacebook} from 'react-icons/ai';
import {AiOutlineTwitter} from 'react-icons/ai';
import {AiFillLinkedin} from 'react-icons/ai';

export default function Footer() {
  return (
    <div className="grid grid-cols-2 py-12 mx-4 mt-4 mb-2 shadow-lg rounded-xl bg-gradient-to-t from-yellow-600 to-yellow-500 px-14">
        <div>
          <p className="text-4xl font-bold text-white font-Play">POSH</p>
          <p className="text-sm text-stone-200">100 Tradecenter Drive, Woburn, MA 01801</p>
          <p className="text-sm text-stone-200">+1 (413) 210-8346</p>
          <p className="text-sm text-stone-200">Copyright © 2022 All Rights Reserved</p>
        </div>
        <div className="flex items-end justify-end space-x-4">
          <AiFillInstagram className="text-2xl text-white"/>
          <AiFillFacebook className="text-2xl text-white"/>
          <AiOutlineTwitter className="text-2xl text-white"/>
          <AiFillLinkedin className="text-2xl text-white"/>
        </div>
    </div>
  );
}
