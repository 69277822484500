import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import { Fragment, useContext, useState } from "react";
import DataContext from "../../context/data-context";
import { FaEdit, FaUserCircle } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { AuthContext } from "../../context/auth-context";
import LoadingSpinner from "../UI/LoadingSpinner";
import { addBankInfo, editPartner } from "../../api/server";
import uploadFile from "../../util/uploadFile";

export default function Profile() {
  const { user } = useContext(AuthContext);
  const { partner, fetchData } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();
  const [uploading, setUploading] = useState(false);
  const [edit, setEdit] = useState(false);

  const editHandler = async () => {
    try {
      setLoading(true);
      setWarning();
      const file = document.getElementById("Photo").files[0];
      const path = `partners/${user.uid}/photo`;
      let photoURL;
      if (file) photoURL = await uploadFile(path, file, setUploading);
      const data = {
        phone: document.getElementById("Phone").value,
        photoURL: photoURL,
      };
      await editPartner(user, data);
      fetchData(() => {
        setEdit(false);
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      setWarning(err.message);
      console.log(err);
    }
  };

  return (
    <div className="bg">
      <Heading
        title="Profile"
        description={`Please note that some of the information 
        cannot be changed because it is used in the 
        Partner's Program Agreement.`}
      >
        {edit ? (
          <Fragment>
            <AiOutlineCheck onClick={editHandler} className="icon" />
            <AiOutlineClose onClick={() => setEdit(false)} className="icon" />
          </Fragment>
        ) : (
          <FaEdit onClick={() => setEdit(true)} className="icon" />
        )}
      </Heading>
      <div className="flex items-center my-8 space-x-4">
        <div className="w-20 h-20">
          {partner.photoURL && (
            <img src={partner.photoURL} className="rounded-full" />
          )}
          {user.photoURL && !partner.photoURL && (
            <img src={user.photoURL} className="rounded-full" />
          )}
          {!user.photoURL && !partner.photoURL && (
            <FaUserCircle className="w-full h-full text-yellow-600 cursor-pointer" />
          )}
        </div>
        {edit && (
          <div className="grid gap-2 text-sm text-stone-200">
            <p className="text-xs text-stone-400">Change Profile Picture:</p>
            <input type="file" id="Photo" />
          </div>
        )}
      </div>

      <div className="info">
        <DetailPair label="Name" value={partner.name} />
        <DetailPair label="Email" value={partner.email} />
        <DetailPair label="Phone" value={partner.phone} edit={edit} />
        <DetailPair label="Address" value={partner.address} />
        <DetailPair label="Date of Birth" value={partner.dob} />
      </div>
      {loading && (
        <div className="flex items-center mt-12 space-x-4">
          <LoadingSpinner />
          {uploading > 0 && (
            <p className="text-sm text-stone-400">Uploading: {uploading}%</p>
          )}
        </div>
      )}
    </div>
  );
}
