import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../UI/Heading";
import Switch from "../UI/Switch";
import DataContext from "../../context/data-context";
import WarningBar from "../UI/WarningBar";
import { IoDocument } from "react-icons/io5";
import { FaFileSignature } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import SettingsOption from "./SettingsOption";
import { FaUserEdit } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";
import { SiCashapp } from "react-icons/si";

export default function Settings() {
  const { partner } = useContext(DataContext);
  const navigate = useNavigate();
  return (
    <div className="grid content-start gap-8 bg">
      <Heading
        title="Settings"
        description="This menu is being developed further. If you need assitance, please contact support."
      ></Heading>
      <div className="grid gap-4 md:m-4">
        <SettingsOption
          onClick={() => navigate("/settings/profile")}
          title="Edit Profile"
        >
          <FaUserEdit className="w-5 h-5" />
        </SettingsOption>
        <SettingsOption
          onClick={() => navigate("/settings/drivers-license")}
          title="Drivers License"
        >
          <FaAddressCard className="w-5 h-5" />
        </SettingsOption>
        <SettingsOption
          onClick={() => navigate("/settings/credit-app")}
          title="Credit Application"
        >
          <SiCashapp className="w-5 h-5" />
        </SettingsOption>
        <SettingsOption
          onClick={() => navigate("/settings/bank-info")}
          title="Bank Account"
        >
          <AiFillBank className="w-5 h-5" />
        </SettingsOption>
        {partner.agreement ? (
          <SettingsOption title="Download Agreement" link={partner.agreement}>
            <IoDocument className="w-5 h-5" />
          </SettingsOption>
        ) : (
          <SettingsOption
            onClick={() => navigate("/settings/agreement")}
            title="Sign Agreement"
          >
            <FaFileSignature className="w-5 h-5" />
          </SettingsOption>
        )}
      </div>
    </div>
  );
}
