import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function DetailPair({ label, value, link, edit, required, placeholder, type }) {
  const navigate = useNavigate();

  const clickHandler = () => {
    if (link) navigate(link);
  };

  const classNames = () => {
    let classes = "";
    if (link) classes += "cursor-pointer hover:opacity-70 text-yellow-600";
    return classes;
  };

  return (
    <div onClick={clickHandler} className={classNames()}>
      <p className="text-stone-400">{label}:</p>
      {!edit && <p className="text-stone-200">{value || "-"}</p>}
      {edit && (
        <input
          className="w-full form-input"
          defaultValue={value}
          id={label}
          required={required}
          type={type || "text"}
          placeholder={placeholder || ""}
        />
      )}
    </div>
  );
}
