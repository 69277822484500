import { fetchPartner } from "../api/server";
import { useState, createContext, useEffect, useContext } from "react";
import { AuthContext } from "./auth-context";

const DataContext = createContext({
  partner: {},
  vehicles: {},
  fetchData: () => {},
  loadingData: true,
});

export const DataContextProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [partner, setPartner] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const fetchData = async (callback) => {
    try {
      setLoadingData(true);
      const data = await fetchPartner(user);
      setPartner(data);
      setVehicles(data.vehicles || []);
      callback && callback();
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (user) fetchData();
    else {
      setPartner();
      setLoadingData(false);
    }
  }, [user]);

  return (
    <DataContext.Provider value={{ partner, vehicles, fetchData, loadingData }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
