import { Fragment, useState, useContext, useEffect } from "react";
import Stats from "./Stats";

import DataContext from "../../context/data-context";
import { getVehicleStats } from "../../api/server";
import { AuthContext } from "../../context/auth-context";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import WarningBar from "../UI/WarningBar";
import BarGraph from "../UI/BarGraph";
import Heading from "../UI/Heading";
import { MdRefresh } from "react-icons/md";

export default function General() {
  const { user } = useContext(AuthContext);
  const { vehicles, partner, fetchData } = useContext(DataContext);
  const [revenue, setRevenue] = useState();
  const [loading, setLoading] = useState(true);

  const fetchVehicleRevenueHandler = async (year) => {
    try {
      setLoading(true);

      // Create Array of all active vehicles
      let vehicleIdArr = [];
      vehicles.forEach((vehicle) => {
        if (vehicle.status === "active") vehicleIdArr.push(vehicle.id);
      });
      const data = await getVehicleStats(user, {
        vehicles: vehicleIdArr,
        year: year,
      });

      // Add Zero revenue for months with no revenue
      if (data.length < 12) {
        const length = data.length;
        for (let i = 0; i < 12 - length; i++) {
          const date = new Date();
          date.setMonth(length + i);
          const month = date.toLocaleString("default", { month: "short" });
          data.push({ name: month, revenue: 0 });
        }
      }

      setRevenue(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVehicleRevenueHandler(new Date().getFullYear());
  }, []);

  return (
    <div className="relative grid content-start gap-6 bg">
      <Heading
        title="Overview"
        description={`The following
      graph shows the revenue generated by your vehicles each
      month. To individually view each vehicle's revenue, go to the Vehicles tab.`}
      >
        <select
          onChange={(e) => fetchVehicleRevenueHandler(e.target.value)}
          className="text-sm cursor-pointer input"
          defaultValue={new Date().getFullYear()}
        >
          {Array.from(
            { length: Number(new Date().getFullYear()) - 2021 },
            (v, i) => 2022 + i
          ).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <MdRefresh onClick={fetchData} className="icon" />
      </Heading>
      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <Fragment>
          <BarGraph data={revenue} />
          <Stats revenue={revenue} />
          {!partner.agreement && (
            <WarningBar
              message="Partner agreement missing"
              link="/settings/agreement"
            />
          )}
        </Fragment>
      )}
    </div>
  );
}
