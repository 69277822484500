import { Fragment, useContext } from "react";
import DataContext from "../../context/data-context";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import LoadingSpinner from "../UI/LoadingSpinner";
import NavigationBar from "./NavigationBar";

export default function Layout({ children }) {
  const { loadingData } = useContext(DataContext);

  return (
    <Fragment>
      <Navbar />
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <div className="grid gap-4 mx-4 mb-4 md:grid-cols-5">
          <Fragment>
            <Sidebar />

            <div className="md:hidden">
              <NavigationBar />
            </div>
            <div className="md:col-span-4">{children}</div>
          </Fragment>
        </div>
      )}
      <Footer />
    </Fragment>
  );
}
