import { useContext, useState, Fragment } from "react";
import SignaturePad from "../UI/SignaturePad";
import { generateAgreement } from "../../api/server";
import { AuthContext } from "../../context/auth-context";
import LoadingSpinner from "../UI/LoadingSpinner";
import { uploadBase64String } from "../../util/uploadBase64String";
import PartnerAgreement from "./PartnerAgreement";
import { BiDownload } from "react-icons/bi";
import AgreementPDF from "../../assets/files/partner-agreement.pdf";

export default function Agreement({ completeSetup }) {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();

  const submitHandler = async (signature) => {
    try {
      setLoading(true);
      const url = await uploadBase64String(
        `partners/${user.uid}/signature`,
        signature
      );
      const agreement = await generateAgreement(user, { signature: url });
      console.log(agreement);
      setLoading(false);
      completeSetup();
    } catch (err) {
      setLoading(false);
      setWarning(err.message);
    }
  };

  return (
    <div className="grid content-start gap-4">
      <div className="flex justify-between">
        <p className="text-lg font-semibold text-stone-200">
          Terms & Conditions
        </p>
        <a
          href={AgreementPDF}
          download="partner-agreement.pdf"
          target="blank"
          rel="noopener noreferrer"
          className="flex items-center p-2 space-x-2 rounded-md btn-primary"
        >
          <BiDownload className="inline-block w-6 h-6 text-stone-200" />
          <p className="text-sm text-stone-200">Download Agreement</p>
        </a>
      </div>
      <hr className="hr" />
      <div className="h-[520px] overflow-scroll">
        <PartnerAgreement />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Fragment>
            <p className="my-4 text-sm text-center text-stone-200">
              Please sign in the box below:
            </p>
            <SignaturePad setSignature={submitHandler} />
          </Fragment>
        )}
      </div>
      {warning && <p className="text-red-600">{warning}</p>}
    </div>
  );
}
