import { MdOutlineImageNotSupported } from "react-icons/md";
import { toTitleCase } from "../../util/toTitleCase";
import { useNavigate } from "react-router-dom";

export default function VehicleCard({ vehicle }) {
  const navigate = useNavigate();

  const classNames = (status) => {
    let classes =  "grid text-sm pill md:grid-cols-5";
    if (status) classes += " border-green-600";
    return classes;
  }

  return (
    <div
      onClick={() => navigate("/vehicles/info?vin=" + vehicle.vin)}
      className={classNames(vehicle.status === "active")}
    >
      {vehicle.image ? (
        <img src={vehicle.image} className="w-20" />
      ) : (
        <MdOutlineImageNotSupported className="w-10 h-10 ml-4 text-stone-400" />
      )}
      <p className="font-semibold text-stone-200 md:col-span-2">
        {vehicle.make} {vehicle.model}
      </p>
      <p className="text-stone-400">{vehicle.year}</p>
      <p className="text-stone-400">{vehicle.license}</p>
    </div>
  );
}
